import axios from 'axios';
import { raiseAPIError } from './exceptions';

const DEBOUNCE_TIME = 1000;

/**
 * API calls returns an objects formatted with pagination
 * {data: [{}, ...], next: 'url', previous: 'url'}
 *
 * @param {object} data response from ApiCall
 * @param {object} data.data the actual data
 * @returns {Array} Array of objects
 */
const handleListData = ({ data }) => {
  if (data.object === 'list') {
    const arrayData = data.data;
    arrayData.object = data.object;
    arrayData.count = data.count;
    arrayData.next = data.next;
    arrayData.previous = data.previous;
    return Promise.resolve(arrayData);
  }
  return Promise.resolve(data);
};

/**
 * returns an integer value, generated by a hashing algorithm
 * source: https://stackoverflow.com/a/8076436
 *
 * @param {string} string A string to be hash
 * @returns {number} a value based on the string
 */
export function hashCode(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = (hash << 5) - hash + string.charCodeAt(i); // eslint-disable-line no-bitwise
  }
  return hash;
}

export default class RestClient {
  #baseUrl = '';

  #authToken = '';

  #debounceRequests = {};

  #onLogout = null;

  /**
   * Cache the request params before making the call.
   * Used to build the storageKey
   */
  requestParams = null;

  constructor(baseUrl, token, onLogout) {
    this.#baseUrl = baseUrl;
    this.#authToken = token;
    this.#onLogout = onLogout;
  }

  /**
   * Config for REST method call
   *
   * @param {object} axiosConfig Config for the request
   * @returns {Promise} Result of the request
   */
  async #request(axiosConfig) {
    const updatedAxiosConfig = { ...axiosConfig };
    updatedAxiosConfig.url = `${this.#baseUrl}${axiosConfig.url}`;

    if (this.#authToken) {
      updatedAxiosConfig.headers = { ...updatedAxiosConfig.headers, Authorization: `Bearer ${this.#authToken}` };
    }
    try {
      return await axios.request(updatedAxiosConfig);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.detail) {
        // return this.#onLogout();
        return raiseAPIError(err.response, this.#onLogout);
      }
      return Promise.reject(err);
    }
  }

  /**
   * Build a unique key to identify duplicate API calls
   *
   * @returns {string} a unique key
   */
  getQueryKey() {
    const { method, path, params } = this.requestParams;
    return [`${method}-${path}-${hashCode(JSON.stringify(params || {}))}`];
  }

  /** REST API methods */

  /**
   * Retrieve data from url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} config Additionnal parameters of the GET call
   * @returns {Promise} Result of the GET call
   */
  async get(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'get' } };
    this.requestParams = { method: 'GET', path: url, params: conf };
    return this.#request(conf);
  }

  /**
   * Delete data at url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} config Additionnal parameters of the DELETE call
   * @returns {Promise} Result of the DELETE call
   */
  async delete(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'delete' } };
    this.requestParams = { method: 'DELETE', path: url, params: conf };
    return this.#request(conf);
  }

  /**
   * Update data at url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} data data to POST
   * @param {object} config Additionnal parameters of the POST call
   * @returns {Promise} Result of the POST call
   */
  async post(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'post' } };
    this.requestParams = { method: 'POST', path: url, params: conf };
    return this.#request(conf);
  }

  /**
   * Update data at url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} data data to PUT
   * @param {object} config Additionnal parameters of the PUT call
   * @returns {Promise} Result of the PUT call
   */
  async put(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'put' } };
    this.requestParams = { method: 'PUT', path: url, params: conf };
    return this.#request(conf);
  }

  /**
   * Submit request at url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} config Additionnal parameters of the HEAD call
   * @returns {Promise} Result of the HEAD call
   */
  async head(url, config) {
    const conf = { ...(config || {}), ...{ url, method: 'head' } };
    this.requestParams = { method: 'HEAD', path: url, params: conf };
    return this.#request(conf);
  }

  /**
   * Update data at url
   *
   * @param {string} url URL on which we are making the call
   * @param {object} data data to PATCH
   * @param {object} config Additionnal parameters of the PATCH call
   * @returns {Promise} Result of the PATCH call
   */
  async patch(url, data, config) {
    const conf = { ...(config || {}), ...{ url, data, method: 'patch' } };
    this.requestParams = { method: 'PATCH', path: url, params: conf };
    return this.#request(conf);
  }

  /** helpers */

  /**
   * Call to delete a resource
   *
   * @param {string} path URL on which we are making the call
   * @param {string} id ressource id
   * @returns {object} updated ressource
   */
  async remove(path, id = 'current') {
    const url = `${path}/${id}`;
    const idArray = { id };
    this.requestParams = { method: 'DELETE', path: url, params: idArray };

    const { data } = await this.delete(url);
    return data;
  }

  /**
   * Call to create a resource
   *
   * @param {string} path URL on which we are making the call
   * @param {object} newData object to create
   * @returns {object} create ressource
   */
  async create(path, newData) {
    this.requestParams = { method: 'POST', path, params: newData };
    const { data } = await this.post(path, newData);
    return data;
  }

  /**
   * Call to update a resource
   *
   * @param {string} path URL on which we are making the call
   * @param {object} newData object to update
   * @param {string} id ressource id
   * @returns {object} updated ressource
   */
  async update(path, newData, id = 'current') {
    const url = `${path}/${id}`;
    this.requestParams = { method: 'PUT', path: url, params: newData };

    const { data } = await this.put(url, newData);
    return data;
  }

  /**
   * Call to update an existing file
   *
   * @param {string} path URL on which we are making the call
   * @param {string} field object key
   * @param {Array} files an array of Files
   * @param {string} id ressource id
   * @returns {object} updated ressource
   */
  async updateFile(path, field, files, id = 'current') {
    const url = `${path}/${id}/file`;
    const formData = new FormData();
    Object.values(files).forEach((file) => {
      if (file instanceof File) {
        formData.append(field, file);
      }
    });

    this.requestParams = { method: 'POST', path: url, params: field };
    const { data } = await this.post(url, formData);
    return data;
  }

  /**
   * Prepare a call to fetch a resource
   *
   * @param {string} path URL on which we are making the call
   * @param {string} id ressource id
   * @param {object} params additionnal parameters
   * @returns {Promise} a promise
   */
  retrieve(path, id = 'current', params = {}) {
    if (!id) return Promise.resolve({ data: null });
    const paramsArray = { ...params, ...{ id } };
    const url = `${path}/${id}`;
    this.requestParams = { method: 'GET', path: url, params: paramsArray };

    return async () => {
      const { data } = await this.get(url, { paramsArray });
      return data;
    };
  }

  /**
   * Prepare a call to list resources
   *
   * @param {string} path URL on which we are making the call
   * @param {object} params additionnal parameters
   * @returns {Promise} a promise
   */
  list(path, params = { limit: 15 }) {
    this.requestParams = { method: 'GET', path, params };
    return async () => {
      const response = await this.get(path, { params });
      return handleListData(response);
    };
  }

  /**
   * Prepare a call to list resources
   * wait {debounceTime} before performing.
   * After waiting we only perform the query if it has not be cancelled.
   *
   * @param {string} path URL on which we are making the call
   * @param {string} objectName a string
   * @param {object} params additionnal parameters
   * @param {number} debounceTime time to wait before executing
   * @returns {Promise} a promise
   */
  listDebounce(path, objectName, params = { limit: 15 }, debounceTime = DEBOUNCE_TIME) {
    // if there is already an ongoing request, then it should be cancelled.
    if (objectName in this.#debounceRequests) {
      this.#debounceRequests[objectName].controller.abort('');
      delete this.#debounceRequests[objectName];
    }

    this.requestParams = { method: 'GET', path, params };

    const delay = (t) =>
      new Promise((resolve) => {
        setTimeout(resolve, t);
      });
    const controller = new AbortController();
    const request = () => this.get(path, { params, signal: controller.signal });
    this.#debounceRequests[objectName] = { controller };
    return async () => {
      const response = await request(await delay(debounceTime));
      return handleListData(response);
    };
  }
}
