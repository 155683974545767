import React, { useState, useContext } from 'react';
import { Modal, TextField, Textarea, Select, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { Params } from 'data';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const AddProductModal = ({ isOpened, refetchData, closeModal }) => {
  const [productName, setProductName] = useState(null);
  const [productCategorie, setProductCategorie] = useState(null);
  const [productDescription, setProductDescription] = useState(null);
  const [productQuantity, setProductQuantity] = useState(0);
  const [productPrice, setProductPrice] = useState(null);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const newProduct = {
    name: productName,
    category: productCategorie,
    price: productPrice,
    description: productDescription,
    quantity: productQuantity,
  };

  const { mutate: createProduct } = useMutation(
    (event) => {
      activate('createProduct');
      event.preventDefault();
      return api.create('product', newProduct);
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        refetchData();
        showToast({
          message: 'Produit créer',
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        closeModal();
        deactivate('createProduct');
      },
    }
  );

  return (
    <Modal
      title="Ajouter un produit"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={createProduct} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p>Entrez les détails du produit :</p>

        <TextField label="Nom" type="text" placeholder="Nom" required onChange={setProductName} />

        <Select
          label="Catégorie"
          helpText="Sélectionnez la catégorie de classification du produit."
          defaultValue={Params.PRODUCTS_CATEGORIES[0]}
          data={Params.PRODUCTS_CATEGORIES}
          onChange={setProductCategorie}
        />

        <Textarea label="Description" placeholder="Description" required onChange={setProductDescription} />

        <TextField label="Quantité" type="text" placeholder="100" required onChange={setProductQuantity} />

        <TextField
          label="Prix unitaire"
          type="text"
          placeholder="50000"
          suffix="F CFA"
          required
          onChange={setProductPrice}
        />
      </form>
    </Modal>
  );
};

AddProductModal.defaultProps = {
  isOpened: false,
  refetchData: null,
  closeModal: null,
};

AddProductModal.propTypes = {
  isOpened: PropTypes.bool,
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AddProductModal;
