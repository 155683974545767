import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModalLayout } from 'components';

const ResetBankAccessModal = ({ isOpened, closeModal }) => {
  const modalAction = () => {};

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction
      modalTitle="Réinitialiser vos accès"
      modalText="Êtes-vous sûr de vouloir réinitialiser vos accès ?"
      validationBtnText="Réinitialiser"
      modalAction={modalAction}
      closeModal={closeModal}
    />
  );
};

ResetBankAccessModal.defaultProps = {
  isOpened: false,
  closeModal: null,
};

ResetBankAccessModal.propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ResetBankAccessModal;
