const Urls = {
  base: '/',
  front: {
    login: '/login',
    password: {
      forgot: '/forgot-password',
      reset: '/reset-password',
    },
    logout: '/logout',
  },
  accounts: '/accounts',
  deposits: '/deposits',
  invoices: '/invoices',
  pilgrims: '/pilgrims',
  products: '/products',
  agencies: '/agencies',
  bank: '/bank',
  group: '/group',
  status: '/status',
};

export default Urls;
