import React, { useContext, useState } from 'react';
import { PageLayout } from 'components';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext } from 'context';
import GroupsTable from './components/GroupTable';

const Group = () => {
  const [cursor, setCursor] = useState('');
  const { api } = useContext(RestClientContext);

  const { data: agencies, refetch } = useQuery({
    queryFn: api.list('agency', { cursor }),
    queryKey: api.getQueryKey(),
  });

  const resultString = agencies ? `${agencies.count} sociétés` : '-';

  return (
    <PageLayout
      title="Groupement"
      displayPrimaryAction={false}
      searchBarPlaceholder="Rechercher une société"
      resultString={resultString}
    >
      <GroupsTable tableData={agencies} setCursor={setCursor} refetchData={refetch} />
    </PageLayout>
  );
};

export default Group;
