import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Alert, showToast } from '@trellixio/roaster-coffee';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Urls } from 'data';
import { validateEmail } from 'helpers';
import { RestClientContext } from 'context';
import { AuthLayout } from 'components';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const navigate = useNavigate();
  const { api } = useContext(RestClientContext);

  const { mutate: checkEmail } = useMutation(
    (event) => {
      event.preventDefault();
      return api.post('auth/forgot-password', { email });
    },
    {
      onSuccess: () => {
        showToast({
          message: `Si l'utilisateur associé à l'email ${email} existe, il ou elle recevra un email de réinitialisation de mot de passe. Pour des raison de sécurité, nous ne pouvons pas vous confirmer si l'utilisateur existe ou non.`,
          level: 'success',
          autoClose: false,
        });
        navigate(Urls.front.login);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const inputFields = (
    <>
      <TextField label="Adresse email" type="email" required onChange={setEmail} />

      <Alert level="info" message="Veuillez entrer votre adresse e-mail pour rechercher votre compte" />
    </>
  );

  const actionButtons = (
    <div>
      <Button variant="primary" color="success" className="full" submit disabled={!isValidForm} onClick={checkEmail}>
        Envoyer l&apos;email de réinitialisation
      </Button>

      <Button variant="inline" onClick={() => navigate(Urls.front.login)}>
        Se connecter
      </Button>
    </div>
  );

  useEffect(() => {
    setIsValidForm(validateEmail(email));
  }, [email]);

  return <AuthLayout title="Trouver votre compte" inputFields={inputFields} actionButtons={actionButtons} />;
};

export default ForgotPassword;
