import { Urls } from 'data';
import { onLogout } from 'helpers';

const Logout = () => {
  onLogout();
  window.location = Urls.base;
  return null;
};

export default Logout;
