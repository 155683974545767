import moment from 'moment';
import { Params, Constants } from 'data';
import Storage from './Storage';

const storage = new Storage(sessionStorage, Constants.APP_NAME);

/**
 * Test if an email address is in the correct format
 *
 * @param {string} email a email we want to check
 * @returns {boolean} Result of the test
 */
export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * Logout action
 *
 * @returns {Function} Logout and user
 */
export const onLogout = () => storage.clear();

/**
 * Get a username based on firstname and lastname
 *
 * @param {string} firstname user firstname
 * @param {string} lastname user lastname
 * @returns {string} a concatenation of firstname and lastname separated by a space
 */
export const getFullName = (firstname, lastname) => {
  return `${firstname} ${lastname}`;
};

/**
 * Formate an amount with the built-in toLocaleString
 *
 * @param {number} amout an amount to formate
 * @returns {string} a concatenation of the amount with the currency F CFA
 */
export const formatAmout = (amout) => {
  return `${amout.toLocaleString('fr-FR')} F CFA`;
};

/**
 * Formate a date using moment js
 *
 * @param {string} date a date to formate
 * @param {string} format the expect format for the date
 * @returns {string} a date to the expected format
 */
export const formatDate = (date, format = 'D/MM/YYYY') => {
  return moment(date).format(format);
};

/**
 * Get the label of a product category based on the value
 *
 * @param {string} categoryValue string to be match with existing value of PRODUCTS_CATEGORIES array
 * @returns {string} The value of label attribute of PRODUCTS_CATEGORIES array corresponding to categoryValue
 */
export const getProductCategoryLabel = (categoryValue) => {
  const category = Params.PRODUCTS_CATEGORIES.filter((catg) => catg.value === categoryValue);
  return category[0].label;
};

/**
 * Get the label of a pilgrim step based on the value
 *
 * @param {string} pilgimStep string to be match with existing value of PILGRIMS_STEPS array
 * @returns {string} The value of label attribute of PILGRIMS_STEPS array corresponding to pilgimStep
 */
export const getPilgrimStepLabel = (pilgimStep) => {
  const data = Params.PILGRIMS_STEPS.filter((step) => step.value === pilgimStep);
  return data[0].label;
};

/**
 * Determine whether or not we should display pagination icon on a table
 *
 * @param {Array} tableData an array of object
 * @returns {boolean} Result of the test
 */
export const showPagination = (tableData) => (tableData ? tableData.count > 10 : false);

/**
 * Determine whether or not there is a next page to display
 *
 * @param {Array} tableData an array of object
 * @returns {boolean} Result of the test
 */
export const hasNextPage = (tableData) => (tableData ? tableData.next !== null : false);

/**
 * Determine whether or not there is a previous page to display
 *
 * @param {Array} tableData an array of object
 * @returns {boolean} Result of the test
 */
export const hasPreviousPage = (tableData) => (tableData ? tableData.previous !== null : false);

/**
 * Retrieve the value of the search parameter cursor from an url
 *
 * @param {string} url an url that was retrieve from a LIST query
 * @returns {string} the cursor parameter
 */
const getQueryCursorFromURL = (url) => {
  const params = new URL(url).searchParams;
  return params.get('cursor');
};

/**
 * Update the value of the cursor set in the LIST query. This allow the user to go from one page to another
 *
 * @param {*} url an url that was retrieve from a LIST query
 * @param {*} setCursor a react method to update a state value
 * @returns {Function} method to update state with data from url
 */
export const updateQueryCursor = (url, setCursor) => setCursor(getQueryCursorFromURL(url));
