import React, { useContext } from 'react';
import { LoadingContext } from 'context';

const GlobalLoader = () => {
  const { isActive } = useContext(LoadingContext);

  if (isActive) {
    return (
      <section className="modal visible loader">
        <div className="flex items-center justify-center h-full">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </section>
    );
  }

  return null;
};

export default GlobalLoader;
