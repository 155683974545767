import React, { useState, useContext } from 'react';
import { Modal, TextField, Textarea, Select, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { Params } from 'data';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const EditProductModal = ({ isOpened, product, refetchData, closeModal }) => {
  const {
    id,
    name: productName,
    description: productDescription,
    category: productCategory,
    quantity: productQuantity,
    price: productPrice,
  } = product;

  const [name, setProductName] = useState(productName);
  const [category, setProductCategory] = useState(productCategory);
  const [description, setProductDescription] = useState(productDescription);
  const [quantity, setProductQuantity] = useState(productQuantity);
  const [price, setProductPrice] = useState(productPrice);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const editedProduct = { name, category, price, description, quantity };

  const { mutate: editProduct } = useMutation(
    () => {
      activate('editProduct');
      return api.update('product', editedProduct, id);
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        refetchData();
        closeModal();
        showToast({
          message: 'Produit édité',
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => deactivate('editProduct'),
    }
  );

  return (
    <Modal
      title="Editer un produit"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={editProduct} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p>
          Entrez les détails du produit <span data-role="name">{productName}</span> :
        </p>

        <TextField
          label="Nom"
          type="text"
          placeholder="Nom"
          defaultValue={productName}
          required
          onChange={setProductName}
        />

        <Select
          label="Catégorie"
          helpText="Sélectionnez la catégorie de classification du produit."
          defaultValue={productCategory}
          data={Params.PRODUCTS_CATEGORIES}
          onChange={setProductCategory}
        />

        <Textarea
          label="Description"
          placeholder="Description"
          defaultValue={productDescription}
          required
          onChange={setProductDescription}
        />

        <TextField
          label="Quantité"
          type="text"
          placeholder="100"
          defaultValue={productQuantity}
          required
          onChange={setProductQuantity}
        />

        <TextField
          label="Prix unitaire"
          type="text"
          placeholder="50000"
          suffix="F CFA"
          defaultValue={productPrice}
          required
          onChange={setProductPrice}
        />
      </form>
    </Modal>
  );
};

EditProductModal.defaultProps = {
  isOpened: false,
  product: null,
  refetchData: null,
  closeModal: null,
};

EditProductModal.propTypes = {
  isOpened: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditProductModal;
