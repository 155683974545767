import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Alert, Tag, showToast } from '@trellixio/roaster-coffee';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Urls } from 'data';
import { LoadingContext, RestClientContext } from 'context';
import { AuthLayout } from 'components';

const ResetPassword = () => {
  const [pwd, setPwd] = useState('');
  const [displayPwd, setDisplayPwd] = useState(false);
  const [pwdRuleSize, setPwdRuleSize] = useState(false);
  const [pwdRuleUppercase, setPwdRuleUppercase] = useState(false);
  const [pwdRuleLowercase, setPwdRuleLowercase] = useState(false);
  const [pwdRuleNumber, setPwdRuleNumber] = useState(false);
  const [pwdRuleSpecial, setPwdRuleSpecial] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { activate, deactivate } = useContext(LoadingContext);
  const { api } = useContext(RestClientContext);

  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const { mutate: resetPassword } = useMutation(
    (event) => {
      activate('ResetPwd');
      event.preventDefault();
      return api.post('auth/reset-password', { token, password: pwd });
    },
    {
      onSuccess: () => {
        showToast({
          message:
            'Votre mot de passe a été configuré avec succès. Veuillez vous connecter à votre compte en utilisant votre nouveau mot de passe.',
          level: 'success',
          autoClose: false,
        });
        navigate(Urls.front.login);
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => deactivate('ResetPwd'),
    }
  );

  const isValidPwd = pwdRuleSize && pwdRuleUppercase && pwdRuleLowercase && pwdRuleNumber && pwdRuleSpecial;

  const toggleDisplayPassword = () => setDisplayPwd((val) => !val);

  const alertMsg = (
    <>
      <p>&nbsp;</p>
      <ul>
        <li>Au moins 8 charactères</li>
        <li>Au moins une lettre majuscule</li>
        <li>Au moins une lettre minuscule</li>
        <li>Au moins un chiffre</li>
        <li>
          Au moins un caractère spécial: <span data-role="name">!@#$%^&*()-=+</span>
        </li>
        <li>
          Ne pas contenir d&apos;information personnelles tel que votre prénom, nom, date de naissance ou toute autre
          information facilement identifiable.
        </li>
      </ul>
    </>
  );

  const inputFields = (
    <>
      <p>Entrer votre mot de passe pour confirmer la création de votre compte.</p>

      <Alert level="info" message={alertMsg} />

      <TextField label="Adresse email" type="email" defaultValue={email} disabled />

      <TextField
        label="Mot de passe"
        type={displayPwd ? 'text' : 'password'}
        rightSection={
          <Button variant="secondary" onClick={toggleDisplayPassword}>
            {displayPwd ? <i className="fa-regular fa-eye-slash" /> : <i className="fa-regular fa-eye" />}
          </Button>
        }
        required
        onChange={setPwd}
      />

      <div className="tags-group">
        <Tag color={`${pwdRuleSize ? 'primary' : 'grey'}`}>8 caractères</Tag>
        <Tag color={`${pwdRuleUppercase ? 'primary' : 'grey'}`}>1 lettre majuscule</Tag>
        <Tag color={`${pwdRuleLowercase ? 'primary' : 'grey'}`}>1 lettre minuscule</Tag>
        <Tag color={`${pwdRuleNumber ? 'primary' : 'grey'}`}>1 chiffre</Tag>
        <Tag color={`${pwdRuleSpecial ? 'primary' : 'grey'}`}>1 caractère spécial</Tag>
      </div>
    </>
  );

  const actionButtons = (
    <Button variant="primary" color="success" className="full" submit disabled={!isValidPwd} onClick={resetPassword}>
      Confirmer
    </Button>
  );

  useEffect(() => {
    setPwdRuleSize(pwd.length > 8);
    setPwdRuleUppercase(/[A-Z]/.test(pwd));
    setPwdRuleLowercase(/[a-z]/.test(pwd));
    setPwdRuleNumber(/\d/.test(pwd));
    setPwdRuleSpecial(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(pwd));
  }, [pwd]);

  return <AuthLayout title="Définir votre mot de passe" inputFields={inputFields} actionButtons={actionButtons} />;
};

export default ResetPassword;
