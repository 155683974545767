import React from 'react';
import { ConfirmModalLayout } from 'components';
import PropTypes from 'prop-types';

const ConfirmAuthorizationRequestModal = ({ isOpened, closeModal }) => {
  const modalAction = () => {};

  const modalText = <p>Êtes-vous sûr de vouloir faire une demande d&apos;autorisation ?</p>;

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction={false}
      modalTitle="Demande d'autorisation"
      modalText={modalText}
      validationBtnText="Confirmer"
      modalAction={modalAction}
      closeModal={closeModal}
    />
  );
};

ConfirmAuthorizationRequestModal.defaultProps = {
  isOpened: false,
  closeModal: null,
};

ConfirmAuthorizationRequestModal.propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ConfirmAuthorizationRequestModal;
