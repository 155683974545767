import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModalLayout } from 'components';

const ConfirmGroupRequestModal = ({ isOpened, agency, closeModal }) => {
  const { name } = agency;
  const modalAction = () => {};

  const modalText = (
    <p>
      Êtes-vous sûr de vouloir transférer tous vos pèlerins à la société <span data-role="name">{name}</span> ?
    </p>
  );

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction={false}
      modalTitle="Demande de groupement"
      modalText={modalText}
      validationBtnText="Confirmer"
      modalAction={modalAction}
      closeModal={closeModal}
    />
  );
};

ConfirmGroupRequestModal.defaultProps = {
  isOpened: false,
  agency: null,
  closeModal: null,
};

ConfirmGroupRequestModal.propTypes = {
  isOpened: PropTypes.bool,
  agency: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  closeModal: PropTypes.func,
};

export default ConfirmGroupRequestModal;
