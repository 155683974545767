import React, { useState } from 'react';
import { TextField, Button } from '@trellixio/roaster-coffee';
import { AuthLayout } from 'components';
import { Constants } from 'data';
import PropTypes from 'prop-types';

const StatusForm = ({ setPilgrim }) => {
  const [identificationNumber, setIndentification] = useState('');
  const [lastname, setLastname] = useState('');

  const searchPilgrim = () => {
    console.log(identificationNumber);
    console.log(lastname);
    console.log(typeof setPilgrim);
  };

  const inputFields = (
    <>
      <p className="content-row">
        Cette interface vous permet de vérifier le status de votre enregistrement au Hadj 2023.
      </p>

      <TextField
        label="Numéro de passeport ou NPI"
        type="text"
        inputClassName="content-row"
        placeholder={Constants.PASSPORT_PLACEHOLDER}
        onChange={setIndentification}
        required
      />

      <TextField
        label="Nom de famille"
        type="text"
        inputClassName="content-row"
        placeholder={Constants.LASTNAME_PLACEHOLDER}
        onChange={setLastname}
        required
      />
    </>
  );

  const actionButtons = (
    <Button variant="primary" color="success" className="full" submit onClick={searchPilgrim}>
      Rechercher un pèlerin
    </Button>
  );

  return <AuthLayout title="Vérification de Status" inputFields={inputFields} actionButtons={actionButtons} />;
};

StatusForm.defaultProps = {
  setPilgrim: null,
};

StatusForm.propTypes = {
  setPilgrim: PropTypes.func,
};

export default StatusForm;
