import React, { useState } from 'react';
import StatusForm from './components/StatusForm';
import StatusDetails from './components/StatusDetails';

const Status = () => {
  const [pilgrim, setPilgrim] = useState(null);

  return pilgrim ? <StatusDetails pilgrim={pilgrim} /> : <StatusForm setPilgrim={setPilgrim} />;
};

export default Status;
