import React, { useState } from 'react';
import { Table, Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { formatDate, formatAmout } from 'helpers';
import invoiceIcon from 'assets/img/invoice.svg';
import DeleteInvoiceModal from './DeleteInvoiceModal';

const InvoicesTable = ({ tableData, refetchData }) => {
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const [selectedInvoice, setInvoice] = useState({});

  const openDeleteModal = (invoice) => {
    setInvoice(invoice);
    setDeleteModalOpened((val) => !val);
  };

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell
            type="text"
            hasTooltip={false}
            tooltipProps={{
              label: `Numéro de référence à utiliser lors du versement.`,
            }}
          >
            {`Référence `}
          </Table.HeadCell>
          <Table.HeadCell type="paragraph">Société</Table.HeadCell>
          <Table.HeadCell
            type="text"
            hasTooltip={false}
            tooltipProps={{
              label: `Le montant à verser à la banque.`,
            }}
          >
            {`Montant `}
          </Table.HeadCell>
          <Table.HeadCell
            type="text"
            hasTooltip={false}
            tooltipProps={{
              label: `Le nombre de dépôts groupé dans la facture.`,
            }}
          >
            {`Dépôts `}
          </Table.HeadCell>
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date de création de la facture.`,
            }}
          >
            {`Date de création `}
          </Table.HeadCell>
          <Table.HeadCell type="text">Banque</Table.HeadCell>
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date de paiement de la facture.`,
            }}
          >
            {`Date de paiement `}
          </Table.HeadCell>
          <Table.HeadCell
            type="enum"
            hasTooltip={false}
            tooltipProps={{
              label: `Confirmation par la banque du paiement de la facture.`,
            }}
          >
            {`Status `}
          </Table.HeadCell>
          <Table.HeadCell type="object">Actions</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data) => (
              <Table.Row key={data.id} id={data.id}>
                <Table.Cell type="thumbnail">
                  <img src={invoiceIcon} alt="" />
                </Table.Cell>
                <Table.Cell type="text">{data.code}</Table.Cell>
                <Table.Cell type="paragraph">{data.agency_name}</Table.Cell>
                <Table.Cell type="text">{formatAmout(data.amount)}</Table.Cell>
                <Table.Cell type="text">{data.deposit}</Table.Cell>
                <Table.Cell type="date">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="paragraph">{data.bank_name}</Table.Cell>
                <Table.Cell type="date">{formatDate(data.paid)}</Table.Cell>
                <Table.Cell type="enum">{data.is_paid}</Table.Cell>
                <Table.Cell type="object">
                  <Button variant="secondary" isIconButton onClick={() => openDeleteModal(data)}>
                    <i className="fa-regular fa-solid fa-trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {Object.keys(selectedInvoice).length > 0 && isDeleteModalOpened && (
        <DeleteInvoiceModal
          isOpened={isDeleteModalOpened}
          invoice={selectedInvoice}
          refetchData={refetchData}
          closeModal={() => setDeleteModalOpened(false)}
        />
      )}
    </>
  );
};

InvoicesTable.defaultProps = {
  tableData: null,
  refetchData: null,
};

InvoicesTable.propTypes = {
  tableData: PropTypes.shape([]),
  refetchData: PropTypes.func,
};

export default InvoicesTable;
