import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import '@trellixio/roaster-coffee/theme-coffee.css';
import { Sidebar, ProtectedRoute, AuthSidebar, LandingPage, GlobalLoader } from 'components';
import { UserContext } from 'context';
import { Urls } from 'data';
import { Login, ForgotPassword, ResetPassword, Logout } from 'pages/auth';
import { Accounts, Agencies, Deposits, Invoices, Pilgrims, Products, Bank, Group, Status } from 'pages';

const App = () => {
  const { isConnected, isSuperAdmin, isAdmin, isAgency, isBank, isPrimaryUser } = useContext(UserContext);

  return (
    <div id="ehadj-main">
      <GlobalLoader />
      {isConnected ? <Sidebar /> : <AuthSidebar />}
      <div className="mainframe grid">
        <Routes>
          <Route index element={<LandingPage />} />

          <Route element={<ProtectedRoute isAllowed={!isConnected} />}>
            <Route path={Urls.front.login} element={<Login />} />
            <Route path={Urls.front.password.reset} element={<ResetPassword />} />
            <Route path={Urls.front.password.forgot} element={<ForgotPassword />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isPrimaryUser} />}>
            <Route path={Urls.accounts} element={<Accounts />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isConnected && !isBank && !isSuperAdmin} />}>
            <Route path={Urls.pilgrims} element={<Pilgrims />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isAdmin} />}>
            <Route path={Urls.agencies} element={<Agencies />} />
            <Route path={Urls.products} element={<Products />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isAgency} />}>
            <Route path={Urls.deposits} element={<Deposits />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isAgency || isAdmin || isBank} />}>
            <Route path={Urls.invoices} element={<Invoices />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isBank} />}>
            <Route path={Urls.bank} element={<Bank />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={isAgency || isAdmin} />}>
            <Route path={Urls.group} element={<Group />} />
          </Route>

          <Route path={Urls.status} element={<Status />} />

          <Route element={<ProtectedRoute isAllowed={isConnected} />}>
            <Route path={Urls.front.logout} element={<Logout />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default App;
