import React, { useState } from 'react';
import { Table, Button, Tag } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { ActivationTag } from 'components';
import {
  formatAmout,
  formatDate,
  getProductCategoryLabel,
  showPagination,
  hasNextPage,
  hasPreviousPage,
  updateQueryCursor,
} from 'helpers';
import productIcon from 'assets/img/product.svg';
import EditProductModal from './EditProductModal';
import UpdateProductStatusModal from './UpdateProductStatusModal';

const ProductsTable = ({ tableData, setCursor, refetchData }) => {
  const [isUpdateStatusModalOpened, setUpdateStatusModalOpened] = useState(false);
  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [selectedProductData, setProductData] = useState({});

  const openUpdateStatusModal = (productData) => {
    setProductData(productData);
    setUpdateStatusModalOpened((val) => !val);
  };

  const openEditModal = (productData) => {
    setProductData(productData);
    setEditModalOpened((val) => !val);
  };

  return (
    <>
      <Table
        showPagination={showPagination(tableData)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">Nom</Table.HeadCell>
          <Table.HeadCell type="text">Prix</Table.HeadCell>
          <Table.HeadCell type="numeric">Quantité</Table.HeadCell>
          <Table.HeadCell type="paragraph">Description</Table.HeadCell>
          <Table.HeadCell type="enum">Actif</Table.HeadCell>
          <Table.HeadCell
            type="text"
            hasTooltip={false}
            tooltipProps={{
              label: `Catégorie de classification du produit.`,
            }}
          >
            {`Catégorie `}
          </Table.HeadCell>
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date de création du produit.`,
            }}
          >
            {`Création `}
          </Table.HeadCell>
          <Table.HeadCell type="object">Actions</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data, key) => (
              <Table.Row key={data.id} id={key}>
                <Table.Cell type="thumbnail">
                  <img src={productIcon} alt="" />
                </Table.Cell>
                <Table.Cell type="text">{data.name}</Table.Cell>
                <Table.Cell type="text">{formatAmout(data.price)}</Table.Cell>
                <Table.Cell type="text">{data.quantity}</Table.Cell>
                <Table.Cell type="paragraph">{data.description}</Table.Cell>
                <Table.Cell type="text">
                  <ActivationTag isActive={data.is_active} />
                </Table.Cell>
                <Table.Cell type="text">
                  <Tag color="default">{getProductCategoryLabel(data.category)}</Tag>
                </Table.Cell>
                <Table.Cell type="text">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="text">
                  {!data.is_required && data.is_active && (
                    <div className="items-group">
                      <Button variant="secondary" isIconButton onClick={() => openEditModal(data)}>
                        <i className="fa-regular fa-solid fa-pen" />
                      </Button>

                      <Button
                        variant="secondary"
                        color="danger"
                        isIconButton
                        onClick={() => openUpdateStatusModal(data)}
                      >
                        <i className="fa-regular fa-solid fa-ban" />
                      </Button>
                    </div>
                  )}
                  {!data.is_required && !data.is_active && (
                    <Button variant="secondary" isIconButton onClick={() => openUpdateStatusModal(data)}>
                      <i className="fa-solid fa-rotate-right" />
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {Object.keys(selectedProductData).length > 0 && (
        <>
          {isEditModalOpened && (
            <EditProductModal
              isOpened={isEditModalOpened}
              product={selectedProductData}
              refetchData={refetchData}
              closeModal={() => setEditModalOpened(false)}
            />
          )}

          {isUpdateStatusModalOpened && (
            <UpdateProductStatusModal
              isOpened={isUpdateStatusModalOpened}
              product={selectedProductData}
              refetchData={refetchData}
              closeModal={() => setUpdateStatusModalOpened(false)}
            />
          )}
        </>
      )}
    </>
  );
};

ProductsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  refetchData: null,
};

ProductsTable.propTypes = {
  tableData: PropTypes.shape([]),
  setCursor: PropTypes.func,
  refetchData: PropTypes.func,
};

export default ProductsTable;
