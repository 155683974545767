import React, { useState, useEffect, useContext } from 'react';
import { Modal, Checkbox } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { formatAmout, getFullName } from 'helpers';
import PropTypes from 'prop-types';
import { RestClientContext } from 'context';
import { ModalFooter } from 'components';

const EditProductsListModal = ({ isOpened, pilgrimData, refetchData, closeModal }) => {
  const { id, pilgrim } = pilgrimData;
  const { first_name: firstname, last_name: lastname } = pilgrim;
  const { api } = useContext(RestClientContext);

  const { data: products } = useQuery({
    queryFn: api.list('product'),
    queryKey: api.getQueryKey(),
  });

  const activeProducts = products ? products.filter((product) => product.is_active === true) : [];

  const [selectedValues, setSelectedValues] = useState([]);

  const modalAction = () => {
    console.log(id);
    console.log(typeof refetchData);
  };

  useEffect(() => {
    if (activeProducts.length > 0) setSelectedValues(activeProducts[0].id);
  }, [activeProducts]);

  return (
    <Modal
      title="Selectionner les options de voyage"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={modalAction} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p>
          Selectionner les options de voyage choisi par le Pèlerin{' '}
          <span data-role="name">{getFullName(firstname, lastname)}</span>
        </p>

        {activeProducts.length > 0 && (
          <Checkbox.Group title="" value={selectedValues} onChange={(e) => setSelectedValues(e)}>
            {activeProducts.map((product) => (
              <Checkbox
                key={product.id}
                isRichSelector
                value={product.id}
                label={
                  <div className="items-group">
                    <div>
                      <h4>{`${product.name} - ${formatAmout(product.price)}`}</h4>
                      <p className="text-secondary">{product.description}</p>
                    </div>
                  </div>
                }
              />
            ))}
          </Checkbox.Group>
        )}
      </form>
    </Modal>
  );
};

EditProductsListModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  refetchData: null,
  closeModal: null,
};

EditProductsListModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({
    id: PropTypes.string,
    pilgrim: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditProductsListModal;
