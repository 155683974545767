import React, { useState, useContext, useCallback } from 'react';
import { Modal, TextField, showToast, DatePicker, Checkbox } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { Constants } from 'data';
import { formatDate } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const AddPilgrimsModal = ({ isOpened, refetchData, closeModal }) => {
  const [pilgrimNPI, setNPI] = useState('');
  const [pilgrimPassport, setPassport] = useState('');
  const [pilgrimFirstname, setFirstname] = useState('');
  const [pilgrimLastname, setLastname] = useState('');
  const [{ month, year }, setDate] = useState({ month: 1, year: 2023 });
  const [pilgrimBirthdate, setBirthdate] = useState(null);
  const [pilgrimIsInternational, setIsInternational] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [passportError, setPassportError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const handleMonthChange = useCallback(
    (targetMonth, targetYear) => setDate({ month: targetMonth, year: targetYear }),
    []
  );

  const newPilgrim = {
    pilgrim: {
      first_name: pilgrimFirstname,
      last_name: pilgrimLastname.toUpperCase(),
      npi: pilgrimNPI,
      passport: pilgrimPassport.toUpperCase(),
      birthdate: formatDate(pilgrimBirthdate, 'YYYY-MM-DD'),
    },
    is_international: pilgrimIsInternational,
  };

  const { mutate: createPilgrim } = useMutation(
    () => {
      activate('createPilgrim');
      return api.create('pilgrim', newPilgrim);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `Le pèlerin ${pilgrimFirstname} ${pilgrimLastname} a été ajouté`,
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        showToast({
          message: error,
          level: 'error',
          autoClose: false,
        });
      },
      onSettled: () => {
        closeModal();
        deactivate('createPilgrim');
      },
    }
  );

  const validateForm = () => {
    const npiCorrectFormat = /^[0-9]{10}$/.test(pilgrimNPI);
    const passportCorrectFormat = /^[A-Z]\d{7}|^\d{2}[A-Z]{2}\d{5}$/.test(pilgrimPassport);
    let npiErrorLocal = false;
    let passportErrorLocal = false;
    let firstnameErrorLocal = false;
    let lastnameErrorLocal = false;

    if (pilgrimNPI.length > 0) {
      npiErrorLocal = !npiCorrectFormat;
    } else if (pilgrimNPI.length === 0) {
      npiErrorLocal = false;
    }

    if (pilgrimPassport.length > 0) {
      passportErrorLocal = !passportCorrectFormat;
    } else if (pilgrimPassport.length === 0) {
      passportErrorLocal = false;
    }

    firstnameErrorLocal = pilgrimFirstname.length === 0;
    lastnameErrorLocal = pilgrimLastname.length === 0;

    if (
      !npiErrorLocal &&
      !passportErrorLocal &&
      !firstnameErrorLocal &&
      !lastnameErrorLocal &&
      (pilgrimNPI.length > 0 || pilgrimPassport.length > 0)
    ) {
      createPilgrim();
    } else {
      setNpiError(npiErrorLocal);
      setPassportError(passportErrorLocal);
      setFirstnameError(firstnameErrorLocal);
      setLastnameError(lastnameErrorLocal);
    }
  };

  return (
    <Modal
      title="Enregister un pèlerin"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={validateForm} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p className="content-row">Entrez les détails du pèlerin :</p>

        <TextField
          label="NPI du pèlerin"
          inputClassName="content-row"
          type="number"
          placeholder={Constants.NPI_PLACEHOLDER}
          pattern="^[0-9]{10}$"
          maxLength="10"
          minLength="10"
          error={npiError ? 'Le format du NPI est incorrect.' : ''}
          onChange={setNPI}
        />

        <TextField
          label="Numéro Passeport"
          inputClassName="content-row"
          type="text"
          placeholder={Constants.PASSPORT_PLACEHOLDER}
          pattern="^[A-Z]\d{7}|^\d[A-Z]{2}\d{6}$"
          minLength="7"
          maxLength="10"
          error={passportError ? 'Le format du numéro passeport est incorrect.' : ''}
          onChange={setPassport}
        />

        <div className="items-group content-row">
          <TextField
            label="Prénom"
            type="text"
            placeholder={Constants.FIRSTNAME_PLACEHOLDER}
            onChange={setFirstname}
            error={firstnameError ? 'Ce champs ne doit pas être vide' : ''}
            required
          />

          <TextField
            label="Nom"
            type="text"
            placeholder={Constants.LASTNAME_PLACEHOLDER}
            onChange={setLastname}
            error={lastnameError ? 'Ce champs ne doit pas être vide' : ''}
            required
          />
        </div>

        <DatePicker
          label="Date de naissance"
          inputClassName="content-row"
          month={month}
          year={year}
          onChange={setBirthdate}
          onMonthChange={handleMonthChange}
          selected={pilgrimBirthdate}
          maxDate={new Date()}
        />

        <p>Résidence</p>
        <div className="option-list checkbox">
          <Checkbox
            label="Cochez cette case, si le pèlerin réside à l'international."
            checked={pilgrimIsInternational}
            onChange={(e) => {
              setIsInternational(e);
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

AddPilgrimsModal.defaultProps = {
  isOpened: false,
  refetchData: null,
  closeModal: null,
};

AddPilgrimsModal.propTypes = {
  isOpened: PropTypes.bool,
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AddPilgrimsModal;
