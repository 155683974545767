import React, { useState } from 'react';
import { Button, TextField, BlockCard, showToast } from '@trellixio/roaster-coffee';
import { PageLayout } from 'components';
import ResetBankAccessModal from './components/ResetBankAccessModal';

const Bank = () => {
  const key = '';
  const [isAddModalOpened, setAddModalOpened] = useState(false);

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const copyKeyValue = () => {
    navigator.clipboard.writeText(key);
    showToast({
      message: `La clé d'accès a été copié`,
      level: 'success',
      autoClose: true,
    });
  };

  return (
    <PageLayout
      title="Clés d'accès"
      isFullPage={false}
      displayPrimaryAction={false}
      displayResultBox={false}
      displaySearchBar={false}
    >
      <form>
        <BlockCard>
          <p className="content-tow">Voici votre clé d&apos;accès à notre API : </p>
          <TextField
            inputClassName="content-tow"
            defaultValue={key}
            disabled
            rightSection={
              <Button variant="secondary" onClick={copyKeyValue}>
                Copier
              </Button>
            }
          />

          <div>
            <Button variant="primary" color="danger" onClick={toggleAddModalOpen}>
              <i className="fa-solid fa-rotate-right" />
              Réinitialiser
            </Button>
          </div>
        </BlockCard>
      </form>

      {isAddModalOpened && <ResetBankAccessModal isOpened={isAddModalOpened} closeModal={toggleAddModalOpen} />}
    </PageLayout>
  );
};

export default Bank;
