import React, { useContext } from 'react';
import { Table, Tag, ActionList, Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { formatDate, formatAmout, getPilgrimStepLabel, Storage } from 'helpers';
import { UserContext } from 'context';
import { Constants } from 'data';
import pilgrimMaleIcon from 'assets/img/pilgrim.svg';
import pilgrimFemaleIcon from 'assets/img/pilgrim-female.svg';

const PilgrimsTableRow = ({ pilgrimData, openHealthModal, openEditModal, openDeleteModal }) => {
  const storage = new Storage(localStorage, Constants.APP_NAME); // for demo
  const { isAgency, isHealthController, isHealthControllerSecondary, isAdmin } = useContext(UserContext);

  const hasActionsEnabled = isAgency || isHealthController;

  const pilgrimIcon = pilgrimData?.pilgrim?.sex === 'M' ? pilgrimMaleIcon : pilgrimFemaleIcon;
  const handleKeyPress = (e, data, callback) => {
    if (e.key === 'Enter') callback(data);
  };

  const report = storage.getData(`report-${pilgrimData.id}`);
  const healthTag = report?.healthy ? <Tag color="primary">Apte</Tag> : <Tag color="danger">Inapte</Tag>;
  const healthStatus = report ? healthTag : 'N/A';
  const hasHealthStatus = healthStatus !== 'N/A';

  return pilgrimData ? (
    <Table.Row id={pilgrimData.id} className={hasHealthStatus && isHealthControllerSecondary ? 'hidden' : ''}>
      <Table.Cell type="thumbnail">
        <img src={pilgrimIcon} alt="" />
      </Table.Cell>
      <Table.Cell type="text" className="text-center">
        {pilgrimData.pilgrim.passport || '-'}{' '}
        {pilgrimData.pilgrim.passport && !pilgrimData.pilgrim.is_passport_valid && (
          // <Tooltip position="right" label="En cours de vérification">
          <Tag color="default" isIconOnly>
            <i className="fa-solid fa-circle-info" />
          </Tag>
          //  </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell type="text" className="text-center">
        {pilgrimData.pilgrim.npi || '-'}{' '}
        {pilgrimData.pilgrim.npi && !pilgrimData.pilgrim.is_npi_valid && (
          // <Tooltip position="right" label="En cours de vérification">
          <Tag color="default" isIconOnly>
            <i className="fa-solid fa-circle-info" />
          </Tag>
          //  </Tooltip>
        )}
      </Table.Cell>
      <Table.Cell type="text">{pilgrimData.pilgrim.first_name}</Table.Cell>
      <Table.Cell type="text">{pilgrimData.pilgrim.last_name}</Table.Cell>
      {(isAgency || isAdmin) && (
        <>
          <Table.Cell type="text">{formatAmout(pilgrimData.deposit_total)}</Table.Cell>
          <Table.Cell type="text">{formatAmout(pilgrimData.cart_total)}</Table.Cell>
          <Table.Cell type="enum">
            {pilgrimData.next_steps.map((step) => (
              <div key={step} className="content-row">
                <Tag color="success">{getPilgrimStepLabel(step)}</Tag>
              </div>
            ))}
          </Table.Cell>
        </>
      )}
      {!isAgency && <Table.Cell type="text">{pilgrimData.agency_name}</Table.Cell>}
      {isHealthController && <Table.Cell type="text">{healthStatus}</Table.Cell>}
      <Table.Cell type="text">{formatDate(pilgrimData.created)}</Table.Cell>
      {hasActionsEnabled && (
        <Table.Cell type="text">
          <ActionList>
            <ActionList.Trigger>
              <Button variant="secondary" isIconButton>
                <i className="fa-regular fa-ellipsis-vertical" />
              </Button>
            </ActionList.Trigger>

            <ActionList.Dropdown>
              <ActionList.Group>
                {isHealthController && (
                  <ActionList.Item>
                    <div
                      className="action-item"
                      role="button"
                      tabIndex={0}
                      onClick={() => openHealthModal(pilgrimData)}
                      onKeyPress={(e) => handleKeyPress(e, pilgrimData, openHealthModal)}
                    >
                      <i className="fa-solid fa-truck-medical" />
                      <span>Visite medicale</span>
                    </div>
                  </ActionList.Item>
                )}

                {isAgency && (
                  <>
                    <ActionList.Item>
                      <div
                        className="action-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => openEditModal(pilgrimData)}
                        onKeyPress={(e) => handleKeyPress(e, pilgrimData, openEditModal)}
                      >
                        <i className="fa-regular fa-edit" />
                        <span>Modifier les infos</span>
                      </div>
                    </ActionList.Item>

                    <ActionList.Item>
                      <div
                        className="action-item danger"
                        role="button"
                        tabIndex={0}
                        onClick={() => openDeleteModal(pilgrimData)}
                        onKeyPress={(e) => handleKeyPress(e, pilgrimData, openDeleteModal)}
                      >
                        <i className="fa-regular fa-trash" />
                        <span>Supprimer le pèlerin</span>
                      </div>
                    </ActionList.Item>
                  </>
                )}
              </ActionList.Group>
            </ActionList.Dropdown>
          </ActionList>
        </Table.Cell>
      )}
    </Table.Row>
  ) : null;
};

PilgrimsTableRow.defaultProps = {
  pilgrimData: null,
  openHealthModal: null,
  openEditModal: null,
  openDeleteModal: null,
};

PilgrimsTableRow.propTypes = {
  pilgrimData: PropTypes.shape({
    id: PropTypes.string,
    deposit_total: PropTypes.number,
    cart_total: PropTypes.number,
    next_steps: PropTypes.arrayOf(PropTypes.string),
    created: PropTypes.string,
    agency_name: PropTypes.string,
    pilgrim: PropTypes.shape({
      sex: PropTypes.string,
      npi: PropTypes.string,
      passport: PropTypes.string,
      is_passport_valid: PropTypes.string,
      is_npi_valid: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  openHealthModal: PropTypes.func,
  openEditModal: PropTypes.func,
  openDeleteModal: PropTypes.func,
};

export default PilgrimsTableRow;
