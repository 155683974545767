import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { Storage } from 'helpers';
import { Constants } from 'data';
import { RestClientContext } from './RestClientProvider';

export const UserContext = React.createContext({});

const UserProvider = ({ children }) => {
  const storage = new Storage(sessionStorage, Constants.APP_NAME);
  const { api } = useContext(RestClientContext);

  const { data: user, refetch: refetchUser } = useQuery({
    queryFn: api.retrieve('user', 'me'),
    queryKey: api.getQueryKey(),
    staleTime: Infinity,
  });

  const isConnected = storage.get(Constants.USER_TOKEN) !== null && user !== undefined;

  const isActive = user && user.is_verified;

  const isSuperAdmin =
    user && (user.role === 'DSI1' || user.role === 'DSI2' || user.role === 'ASIN1' || user.role === 'ASIN2'); // DSI or ASIN user

  const isAdmin = user && (user.role === 'AGLO1' || user.role === 'AGLO2'); // Any AGLO user
  const isAdminPrimary = user && user.role === 'AGLO1'; // AGLO primary user
  const isAdminSecondary = user && user.role === 'AGLO2'; // AGLO secondary user

  const isAgency = user && (user.role === 'SA1' || user.role === 'SA2'); // Any SA user
  const isAgencyPrimary = user && user.role === 'SA1'; // SA primary user
  const isAgencySecondary = user && user.role === 'SA2'; // SA secondary user

  const isHealthController = user && (user.role === 'MS1' || user.role === 'MS2'); // Any MS user
  const isHealthControllerPrimary = user && user.role === 'MS1'; // MS primary user
  const isHealthControllerSecondary = user && user.role === 'MS2'; // MS secondary user

  const isVisaController = user && (user.role === 'MAEC1' || user.role === 'MAEC2'); // Any MAEC user
  const isVisaControllerPrimary = user && user.role === 'MAEC1'; // MAEC primary user
  const isVisaControllerSecondary = user && user.role === 'MAEC2'; // MAEC secondary user

  const isBank = user && (user.role === 'BANK1' || user.role === 'BANK2'); // Any BANK user
  const isBankPrimary = user && user.role === 'BANK1'; // BANK primary user
  const isBankSecondary = user && user.role === 'BANK2'; // BANK secondary user

  const isPrimaryUser =
    isSuperAdmin ||
    isAdminPrimary ||
    isAgencyPrimary ||
    isHealthControllerPrimary ||
    isVisaControllerPrimary ||
    isBankPrimary;

  const memoValues = useMemo(
    () => ({
      user,
      isActive,
      isConnected,
      isSuperAdmin,
      isAdmin,
      isAdminPrimary,
      isAdminSecondary,
      isAgency,
      isAgencyPrimary,
      isAgencySecondary,
      isHealthController,
      isHealthControllerPrimary,
      isHealthControllerSecondary,
      isVisaController,
      isVisaControllerPrimary,
      isVisaControllerSecondary,
      isBank,
      isBankPrimary,
      isBankSecondary,
      isPrimaryUser,
      refetchUser,
    }),
    [user]
  );

  return <UserContext.Provider value={memoValues}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

export default UserProvider;
