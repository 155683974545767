import React, { useState, useContext } from 'react';
import { Modal, TextField, showToast } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getFullName } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const EditAccountModal = ({ isOpened, user, refetchData, closeModal }) => {
  const { id, email, first_name: userFirstname, last_name: userLastname, organization } = user;

  const { name: orgName } = organization;

  const [firstName, setFirstName] = useState(userFirstname);
  const [lastName, setLastName] = useState(userLastname);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const { mutate: editAccount } = useMutation(
    () => {
      activate('editAccount');
      const newData = { first_name: firstName, last_name: lastName };
      return api.update('user', newData, id);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `L'utilisateur ${firstName} ${lastName} a été mise à jour.`,
          level: 'success',
          autoClose: true,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        deactivate('editAccount');
        closeModal();
      },
    }
  );

  return (
    <Modal
      title="Editer l'organisation"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={editAccount} secondaryBtnAction={closeModal} />}
    >
      <form>
        <TextField inputClassName="content-row" label="Organisation" defaultValue={orgName} disabled />

        <p className="content-row">{`Mettre à jour le compte de ${getFullName(userFirstname, userLastname)} :`}</p>

        <div className="items-group content-row">
          <TextField label="Prénom" type="text" defaultValue={userFirstname} onChange={setFirstName} />
          <TextField label="Nom" type="text" defaultValue={userLastname} onChange={setLastName} />
        </div>

        <TextField label="Adresse email" type="email" defaultValue={email} disabled />
      </form>
    </Modal>
  );
};

EditAccountModal.defaultProps = {
  isOpened: false,
  user: null,
  refetchData: null,
  closeModal: null,
};

EditAccountModal.propTypes = {
  isOpened: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    organization: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditAccountModal;
