import React from 'react';
import { Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const ModalFooter = ({
  displayPrimaryBtn,
  primaryBtnLabel,
  primaryBtnColor,
  primaryBtnAction,
  primaryBtnDisabled,
  displaySecondaryBtn,
  secondaryBtnLabel,
  secondaryBtnAction,
}) => {
  return (
    <div className="items-group">
      {displaySecondaryBtn && (
        <Button variant="secondary" onClick={secondaryBtnAction}>
          {secondaryBtnLabel}
        </Button>
      )}

      {displayPrimaryBtn && (
        <Button
          variant="primary"
          color={primaryBtnColor}
          disabled={primaryBtnDisabled}
          submit
          onClick={primaryBtnAction}
        >
          {primaryBtnLabel}
        </Button>
      )}
    </div>
  );
};

ModalFooter.defaultProps = {
  displayPrimaryBtn: true,
  primaryBtnLabel: 'Enregistrer',
  primaryBtnColor: 'success',
  primaryBtnDisabled: false,
  primaryBtnAction: null,
  displaySecondaryBtn: true,
  secondaryBtnLabel: 'Annuler',
  secondaryBtnAction: null,
};

ModalFooter.propTypes = {
  displayPrimaryBtn: PropTypes.bool,
  primaryBtnLabel: PropTypes.string,
  primaryBtnColor: PropTypes.string,
  primaryBtnDisabled: PropTypes.bool,
  primaryBtnAction: PropTypes.func,
  displaySecondaryBtn: PropTypes.bool,
  secondaryBtnLabel: PropTypes.string,
  secondaryBtnAction: PropTypes.func,
};

export default ModalFooter;
