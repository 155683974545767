import React, { useState, useContext } from 'react';
import { Table } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { showPagination, hasNextPage, hasPreviousPage, updateQueryCursor } from 'helpers';
import { UserContext } from 'context';
import PilgrimsTableRow from './PilgrimsTableRow';
import EditPilgrimsModal from './EditPilgrimsModal';
import DeletePilgrimModal from './DeletePilgrimModal';
import HealthCheckModal from './HealthCheckModal';
import AddDepositModal from '../../deposits/components/AddDepositModal';
import EditProductsListModal from '../../products/components/EditProductsListModal';

const PilgrimsTable = ({ tableData, setCursor, refetchData }) => {
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [isProductsModalOpened, setProductsModalOpened] = useState(false);
  const [isDepositModalOpened, setDepositModalOpened] = useState(false);
  const [isHeathModalOpened, setHeathModalOpened] = useState(false);
  const [selectedPilgrimData, setPilgrimData] = useState({});
  const { isAgency, isHealthController, isAdmin } = useContext(UserContext);

  const openDeleteModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setDeleteModalOpened((val) => !val);
  };

  const openEditModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setEditModalOpened((val) => !val);
  };

  // const openProductsModal = (pilgrimData) => {
  //   setPilgrimData(pilgrimData);
  //   setProductsModalOpened((val) => !val);
  // };

  // const openDepositModal = (pilgrimData) => {
  //   setPilgrimData(pilgrimData);
  //   setDepositModalOpened((val) => !val);
  // };

  const openHealthModal = (pilgrimData) => {
    setPilgrimData(pilgrimData);
    setHeathModalOpened((val) => !val);
  };

  const hasActionsEnabled = isAgency || isHealthController;

  return (
    <>
      <Table
        showPagination={showPagination(tableData)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">Passeport</Table.HeadCell>
          <Table.HeadCell type="text">NPI</Table.HeadCell>
          <Table.HeadCell type="text">Prénoms</Table.HeadCell>
          <Table.HeadCell
            type="text"
            hasTooltip={false}
            tooltipProps={{
              label: 'Nom de famille.',
            }}
          >
            {`Nom `}
          </Table.HeadCell>
          {(isAgency || isAdmin) && (
            <>
              <Table.HeadCell
                type="text"
                hasTooltip={false}
                tooltipProps={{
                  label: 'Le total du montant versé par le pèlerin à la société agréée.',
                }}
              >
                {`Dépôt `}
              </Table.HeadCell>
              <Table.HeadCell
                type="text"
                hasTooltip={false}
                tooltipProps={{
                  label: 'Le montant total de la commande effectuée par le pélerin y compris les options choisis.',
                }}
              >
                {`Commande `}
              </Table.HeadCell>
              <Table.HeadCell
                type="enum"
                hasTooltip={false}
                tooltipProps={{
                  label: 'Le pèlerin doit effectuer les démarches qui suivent afin de faire avancer le dossier.',
                }}
              >
                {`Étape suivante `}
              </Table.HeadCell>
            </>
          )}

          {!isAgency && (
            <Table.HeadCell
              type="text"
              hasTooltip={false}
              tooltipProps={{
                label: `La société ayant effectué l'enregistrement du pèlerin.`,
              }}
            >
              {`Société `}
            </Table.HeadCell>
          )}
          {isHealthController && (
            <Table.HeadCell
              type="text"
              hasTooltip={false}
              tooltipProps={{
                label: `Le statut médical du pèlerin`,
              }}
            >
              {`Statut `}
            </Table.HeadCell>
          )}
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date d'enregistrement du pèlerin.`,
            }}
          >
            {`Création `}
          </Table.HeadCell>
          {hasActionsEnabled && <Table.HeadCell type="object">Actions</Table.HeadCell>}
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data) => (
              <PilgrimsTableRow
                key={data.id}
                pilgrimData={data}
                openHealthModal={openHealthModal}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
              />
            ))}
          </Table.Body>
        )}
      </Table>

      {hasActionsEnabled && Object.keys(selectedPilgrimData).length > 0 && (
        <>
          {isAgency && (
            <>
              {isEditModalOpened && (
                <EditPilgrimsModal
                  isOpened={isEditModalOpened}
                  pilgrimData={selectedPilgrimData}
                  refetchData={refetchData}
                  closeModal={() => setEditModalOpened(false)}
                />
              )}

              {isDeleteModalOpened && (
                <DeletePilgrimModal
                  isOpened={isDeleteModalOpened}
                  pilgrimData={selectedPilgrimData}
                  refetchData={refetchData}
                  closeModal={() => setDeleteModalOpened(false)}
                />
              )}

              {isProductsModalOpened && (
                <EditProductsListModal
                  isOpened={isProductsModalOpened}
                  pilgrimData={selectedPilgrimData}
                  refetchData={refetchData}
                  closeModal={() => setProductsModalOpened(false)}
                />
              )}

              {isDepositModalOpened && (
                <AddDepositModal
                  isOpened={isDepositModalOpened}
                  pilgrimData={selectedPilgrimData}
                  refetchData={refetchData}
                  closeModal={() => setDepositModalOpened(false)}
                />
              )}
            </>
          )}

          {isHealthController && isHeathModalOpened && (
            <HealthCheckModal
              isOpened={isHeathModalOpened}
              pilgrimData={selectedPilgrimData}
              refetchData={refetchData}
              closeModal={() => setHeathModalOpened(false)}
            />
          )}
        </>
      )}
    </>
  );
};

PilgrimsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  refetchData: null,
};

PilgrimsTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  setCursor: PropTypes.func,
  refetchData: PropTypes.func,
};

export default PilgrimsTable;
