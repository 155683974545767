import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { showToast } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import { ConfirmModalLayout } from 'components';
import { LoadingContext, RestClientContext } from 'context';

const UpdateProductStatusModal = ({ isOpened, product, refetchData, closeModal }) => {
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const { id, name, is_active: isActive } = product;

  const { mutate: updateProductStatus } = useMutation(
    () => {
      activate('updateProductStatus');
      return api.update('product', { is_active: !isActive }, id);
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        refetchData();
        closeModal();
        showToast({
          message: 'Produit disabled',
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => deactivate('updateProductStatus'),
    }
  );

  const modalText = (
    <>
      <p>
        Êtes-vous sûr de vouloir {isActive ? 'désactiver' : 'activer'} le produit <span data-role="name">{name}</span>
      </p>
      <p>&nbsp;</p>
      {isActive && (
        <p>
          Une fois le produit désactivé il ne sera plus disponbile aux nouveaux pèlerins. Cependant il fera toujours
          parti de la commande de ceux qui l&apos;ont déjà sélectionné.
        </p>
      )}

      {!isActive && <p>Une fois le produit activé il sera disponbile aux nouveaux pèlerins.</p>}
    </>
  );

  const modalTitle = isActive ? 'Désactiver le produit' : 'Activer le produit';

  const validationBtnText = isActive ? 'Désactiver' : 'Activer';

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction={isActive}
      modalTitle={modalTitle}
      modalText={modalText}
      validationBtnText={validationBtnText}
      modalAction={updateProductStatus}
      closeModal={closeModal}
    />
  );
};

UpdateProductStatusModal.defaultProps = {
  isOpened: false,
  product: null,
  refetchData: null,
  closeModal: null,
};

UpdateProductStatusModal.propTypes = {
  isOpened: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    is_active: PropTypes.bool,
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default UpdateProductStatusModal;
