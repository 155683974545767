import React, { useState, useContext } from 'react';
import { PageLayout } from 'components';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext } from 'context';
import AddAgencyModal from './components/AddAgencyModal';
import AgenciesTable from './components/AgenciesTable';

const Agencies = () => {
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [cursor, setCursor] = useState('');
  const { api } = useContext(RestClientContext);

  const { data: agencies, refetch } = useQuery({
    queryFn: api.list('agency', { cursor }),
    queryKey: api.getQueryKey(),
  });

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const resultString = agencies ? `${agencies.count} sociétés` : '-';

  return (
    <PageLayout
      title="Sociétés agréées"
      displayPrimaryAction
      primaryActionTitle="Ajouter une société"
      primaryAction={toggleAddModalOpen}
      searchBarPlaceholder="Rechercher une sociiété"
      resultString={resultString}
    >
      <AgenciesTable tableData={agencies} setCursor={setCursor} refetchData={refetch} />

      {isAddModalOpened && (
        <AddAgencyModal isOpened={isAddModalOpened} refetchData={refetch} closeModal={toggleAddModalOpen} />
      )}
    </PageLayout>
  );
};

export default Agencies;
