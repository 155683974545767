import React, { useContext } from 'react';
import { Button } from '@trellixio/roaster-coffee';
import { Urls } from 'data';
import { getFullName } from 'helpers';
import { UserContext } from 'context';
import appLogo from 'assets/img/ehadj-logo.svg';
import adminIcon from 'assets/img/admin.svg';
import agencyIcon from 'assets/img/agency.svg';
import depositIcon from 'assets/img/deposit.svg';
import invoiceIcon from 'assets/img/invoice.svg';
import pilgrimcon from 'assets/img/pilgrim.svg';
import productIcon from 'assets/img/product.svg';
import logoutIcon from 'assets/img/logout.svg';
import govIcon from 'assets/img/gov.svg';
import bankIcon from 'assets/img/bank.svg';
import SidebarItem from './SidebarItem';

const Sidebar = () => {
  const { user, isSuperAdmin, isAdmin, isAgency, isBank, isPrimaryUser } = useContext(UserContext);
  const { first_name: firstname, last_name: lastname, organization } = user;

  const userIcon = () => {
    if (isAgency) return agencyIcon;

    if (isBank) return bankIcon;

    return govIcon;
  };

  const userOrganization = isAgency || isBank ? organization.name : organization.type;

  return (
    <aside id="sidebar" className="appnav-container">
      <nav className="appnav">
        <div className="appnav-section header">
          <div className="app-logo">
            <img src={appLogo} alt="logo" />
          </div>
        </div>

        <div className="appnav-section">
          <ul>
            {isBank && <SidebarItem itemName="Clés d'accès" path={Urls.bank} itemIcon={adminIcon} />}

            {!isBank && !isSuperAdmin && <SidebarItem itemName="Pèlerins" path={Urls.pilgrims} itemIcon={pilgrimcon} />}

            {isAgency && <SidebarItem itemName="Dépots" path={Urls.deposits} itemIcon={depositIcon} />}

            {(isAgency || isAdmin || isBank) && (
              <SidebarItem itemName="Factures" path={Urls.invoices} itemIcon={invoiceIcon} />
            )}

            {isAdmin && (
              <>
                <SidebarItem itemName="Sociétés" path={Urls.agencies} itemIcon={agencyIcon} />
                <SidebarItem itemName="Produits" path={Urls.products} itemIcon={productIcon} />
              </>
            )}

            {(isAdmin || isAgency) && <SidebarItem itemName="Groupement" path={Urls.group} itemIcon={adminIcon} />}

            {isPrimaryUser && <SidebarItem itemName="Administrateurs" path={Urls.accounts} itemIcon={adminIcon} />}
          </ul>
        </div>

        <div className="appnav-section footer">
          <ul>
            <SidebarItem itemName="Se déconnecter" path={Urls.front.logout} itemIcon={logoutIcon} />
          </ul>
          <Button variant="secondary" className="store-profile">
            <div className="user">
              <img src={userIcon()} alt="Profile logo" />
              <div>
                <p>{userOrganization}</p>
                <span>{getFullName(firstname, lastname)}</span>
              </div>
            </div>
          </Button>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
