import React, { useState, useEffect, useContext } from 'react';
import { ActionList, Button } from '@trellixio/roaster-coffee';
import { PageLayout } from 'components';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext, UserContext } from 'context';
import AddAccountModal from './components/AddAccountModal';
import AccountsTable from './components/AccountsTable';
import ImportBadgeModal from './components/ImportBadgeModal';
import ConfirmAuthorizationRequestModal from './components/ConfirmAuthorizationRequestModal';

const Accounts = () => {
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [isImportModalOpened, setImportModalOpened] = useState(false);
  const [isAuthorizationModalOpened, setAuthorizationModalOpened] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [cursor, setCursor] = useState('');
  const { api } = useContext(RestClientContext);
  const { isAgencyPrimary } = useContext(UserContext);

  const { data: usersList, refetch } = useQuery({
    queryFn: api.list('user', { cursor }),
    queryKey: api.getQueryKey(),
  });

  const { data: organizationsData } = useQuery({
    queryFn: api.list('organization'),
    queryKey: api.getQueryKey(),
  });

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const toggleImportModalOpen = () => setImportModalOpened((val) => !val);

  const toggleAuthorizationModalOpen = () => setAuthorizationModalOpened((val) => !val);

  const resultString = usersList ? `${usersList.count} comptes` : '-';

  const handleKeyPress = (e, callback) => {
    if (e.key === 'Enter') callback();
  };

  const secondaryAction = isAgencyPrimary ? (
    <ActionList>
      <ActionList.Trigger>
        <Button variant="secondary" isIconButton>
          <i className="fa-regular fa-ellipsis-vertical" />
        </Button>
      </ActionList.Trigger>

      <ActionList.Dropdown>
        <ActionList.Group>
          <ActionList.Item>
            <div
              className="action-item"
              role="button"
              tabIndex={0}
              onClick={toggleImportModalOpen}
              onKeyPress={(e) => handleKeyPress(e, toggleImportModalOpen)}
            >
              <span>Ajouter votre badge</span>
            </div>
          </ActionList.Item>
        </ActionList.Group>
        <ActionList.Divider />
        <ActionList.Group>
          <ActionList.Item>
            <div
              className="action-item"
              role="button"
              tabIndex={0}
              onClick={toggleAuthorizationModalOpen}
              onKeyPress={(e) => handleKeyPress(e, toggleAuthorizationModalOpen)}
            >
              <span>Faire une demande d&apos;autorisation</span>
            </div>
          </ActionList.Item>
        </ActionList.Group>
      </ActionList.Dropdown>
    </ActionList>
  ) : null;

  useEffect(() => {
    if (organizationsData) {
      const organizationsLocal = [...organizationsData];
      organizationsLocal.map((organizationLocal) => {
        const org = organizationLocal;
        org.value = organizationLocal.id;
        org.label = organizationLocal.name;
        return org;
      });

      setOrganizations(organizationsLocal);
    }
  }, [organizationsData]);

  return (
    <PageLayout
      title="Administrateurs"
      searchBarPlaceholder="Rechercher une organisation"
      displayPrimaryAction
      primaryAction={toggleAddModalOpen}
      secondaryActionBtn={secondaryAction}
      resultString={resultString}
    >
      <AccountsTable tableData={usersList} setCursor={setCursor} refetchData={refetch} />

      {isAddModalOpened && (
        <AddAccountModal
          isOpened={isAddModalOpened}
          organizations={organizations}
          refetchData={refetch}
          closeModal={toggleAddModalOpen}
        />
      )}

      {isAgencyPrimary && (
        <>
          {isImportModalOpened && (
            <ImportBadgeModal isOpened={isImportModalOpened} closeModal={toggleImportModalOpen} />
          )}

          {isAuthorizationModalOpened && (
            <ConfirmAuthorizationRequestModal
              isOpened={isAuthorizationModalOpened}
              closeModal={toggleAuthorizationModalOpen}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default Accounts;
