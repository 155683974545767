import React from 'react';
import { Modal } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import ModalFooter from './ModalFooter';

const ConfirmModalLayout = ({
  isOpened,
  isDeactivateAction,
  modalTitle,
  modalText,
  validationBtnText,
  modalAction,
  closeModal,
}) => {
  return (
    <Modal
      title={modalTitle}
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={
        <ModalFooter
          primaryBtnLabel={validationBtnText}
          primaryBtnColor={`${isDeactivateAction ? 'danger' : 'success'}`}
          primaryBtnAction={modalAction}
          secondaryBtnAction={closeModal}
        />
      }
    >
      <p>{modalText}</p>
    </Modal>
  );
};

ConfirmModalLayout.defaultProps = {
  isOpened: false,
  isDeactivateAction: false,
  modalTitle: null,
  modalText: null,
  validationBtnText: null,
  modalAction: null,
  closeModal: null,
};

ConfirmModalLayout.propTypes = {
  isOpened: PropTypes.bool,
  isDeactivateAction: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalText: PropTypes.node,
  validationBtnText: PropTypes.string,
  modalAction: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ConfirmModalLayout;
