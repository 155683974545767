import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Alert } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import { Urls, Constants } from 'data';
import { Storage } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';
import { AuthLayout } from 'components';

const Login = () => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [displayPwd, setDisplayPwd] = useState(false);
  const formData = new FormData();
  const storage = new Storage(sessionStorage, Constants.APP_NAME);
  const { api } = useContext(RestClientContext);

  const navigate = useNavigate();

  const { activate, deactivate } = useContext(LoadingContext);

  const { mutate: logUser } = useMutation(
    (event) => {
      activate('Login');
      formData.append('username', email);
      formData.append('password', pwd);

      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      };

      event.preventDefault();
      return api.post('auth/login', formData, { headers });
    },
    {
      onSuccess: ({ data }) => {
        storage.set(Constants.USER_TOKEN, data.access_token);
        setDisplayError(false);
        window.location = Urls.base;
      },
      onError: () => {
        setDisplayError(true);
      },
      onSettled: () => deactivate('Login'),
    }
  );

  const toggleDisplayPassword = () => setDisplayPwd((val) => !val);

  const inputFields = (
    <>
      {displayError ? <Alert level="error" message="Adresse email ou mot de passe incorrect" /> : null}

      <TextField label="Adresse email" type="email" onChange={setEmail} placeholder="email@example.com" required />

      <TextField
        label="Mot de passe"
        type={displayPwd ? 'text' : 'password'}
        placeholder="********"
        rightSection={
          <Button variant="secondary" onClick={toggleDisplayPassword}>
            {displayPwd ? <i className="fa-regular fa-eye-slash" /> : <i className="fa-regular fa-eye" />}
          </Button>
        }
        onChange={setPwd}
        required
      />

      <Button variant="inline" onClick={() => navigate(Urls.front.password.forgot)}>
        Mot de passe oublié ?
      </Button>
    </>
  );

  const actionButtons = (
    <Button variant="primary" color="success" className="full" submit onClick={logUser}>
      Continuer
    </Button>
  );

  return <AuthLayout title="Connexion" inputFields={inputFields} actionButtons={actionButtons} />;
};

export default Login;
