import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { showToast } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import { ConfirmModalLayout } from 'components';
import { getFullName } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';

const UpdateAccountStatusModal = ({ isOpened, user, refetchData, closeModal }) => {
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);
  const { id, email, first_name: firstName, last_name: lastName, is_active: isActive } = user;

  const { mutate: updateAccountStatus } = useMutation(
    () => {
      activate('updateAccountStatus');
      const endpoint = isActive ? 'deactivate' : 'activate';
      return api.post(`user/${id}/${endpoint}`);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `L'utilisateur ${firstName} ${lastName} a été ${isActive ? 'désactivé' : 'activé'}.`,
          level: 'success',
          autoClose: true,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        deactivate('updateAccountStatus');
        closeModal();
      },
    }
  );

  const modalText = (
    <span>
      Êtes-vous sûr de vouloir {isActive ? 'désactiver' : 'activer'} le compte de{' '}
      <span data-role="name">{getFullName(firstName, lastName)}</span> associé à l&apos;email{' '}
      <span data-role="name">{email}</span> ?
    </span>
  );

  const modalTitle = isActive ? 'Désactivatation du compte' : 'Activatation du compte';

  const validationBtnText = isActive ? 'Désactivater' : 'Activer';

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction={isActive}
      modalTitle={modalTitle}
      modalText={modalText}
      validationBtnText={validationBtnText}
      modalAction={updateAccountStatus}
      closeModal={closeModal}
    />
  );
};

UpdateAccountStatusModal.defaultProps = {
  isOpened: false,
  user: null,
  refetchData: null,
  closeModal: null,
};

UpdateAccountStatusModal.propTypes = {
  isOpened: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    is_active: PropTypes.bool,
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default UpdateAccountStatusModal;
