import React from 'react';
import { Tag } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const ActivationTag = ({ isActive }) => {
  return isActive ? (
    <Tag className="rounded" color="green" isIconOnly>
      <i className="fa-regular fa-check" />
    </Tag>
  ) : (
    <Tag className="rounded" color="error" isIconOnly>
      <i className="fa-regular fa-xmark" />
    </Tag>
  );
};

ActivationTag.defaultProps = {
  isActive: false,
};

ActivationTag.propTypes = {
  isActive: PropTypes.bool,
};

export default ActivationTag;
