import React, { useState } from 'react';
import { Table, Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { getFullName, formatDate, showPagination, hasNextPage, hasPreviousPage, updateQueryCursor } from 'helpers';
import { ActivationTag } from 'components';
import agencyIcon from 'assets/img/agency.svg';
import EditAgencyModal from './EditAgencyModal';

const AgenciesTable = ({ tableData, setCursor, refetchData }) => {
  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [selectedAgencyData, setAgencyData] = useState({});

  const openEditModal = (orgData) => {
    setAgencyData(orgData);
    setEditModalOpened((val) => !val);
  };

  return (
    <>
      <Table
        showPagination={showPagination(tableData)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">Nom</Table.HeadCell>
          <Table.HeadCell type="text">IFU</Table.HeadCell>
          <Table.HeadCell type="text">Téléphone</Table.HeadCell>
          <Table.HeadCell type="Paragraph">Adresse</Table.HeadCell>
          <Table.HeadCell type="text">Administrateur</Table.HeadCell>
          <Table.HeadCell type="text">Email</Table.HeadCell>
          <Table.HeadCell
            type="enum"
            hasTooltip={false}
            tooltipProps={{
              label: `Status de l'activation du compte de l'utilisateur principal.`,
            }}
          >
            {`Actif `}
          </Table.HeadCell>
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date de l'enregistrement de la société.`,
            }}
          >
            {`Création `}
          </Table.HeadCell>
          <Table.HeadCell type="object">Actions</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data, key) => (
              <Table.Row key={data.id} id={key}>
                <Table.Cell type="thumbnail">
                  <img src={agencyIcon} alt="" />
                </Table.Cell>
                <Table.Cell type="text">
                  <div className="items-group">{data.name}</div>
                </Table.Cell>
                <Table.Cell type="text">{data.ifu}</Table.Cell>
                <Table.Cell type="text">{data.phone}</Table.Cell>
                <Table.Cell type="paragraph">{data.address}</Table.Cell>
                <Table.Cell type="text">{getFullName(data.admin.first_name, data.admin.last_name)}</Table.Cell>
                <Table.Cell type="text">{data.admin.email}</Table.Cell>
                <Table.Cell type="text">
                  <ActivationTag isActive={data.admin.is_active} />
                </Table.Cell>
                <Table.Cell type="date">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="text">
                  <div className="items-group">
                    <Button variant="secondary" isIconButton onClick={() => openEditModal(data)}>
                      <i className="fa-regular fa-solid fa-pen" />
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {Object.keys(selectedAgencyData).length > 0 && isEditModalOpened && (
        <EditAgencyModal
          isOpened={isEditModalOpened}
          agency={selectedAgencyData}
          refetchData={refetchData}
          closeModal={() => setEditModalOpened(false)}
        />
      )}
    </>
  );
};

AgenciesTable.defaultProps = {
  tableData: null,
  setCursor: null,
  refetchData: null,
};

AgenciesTable.propTypes = {
  tableData: PropTypes.shape([]),
  setCursor: PropTypes.func,
  refetchData: PropTypes.func,
};

export default AgenciesTable;
