import React, { useState, useContext } from 'react';
import { Modal, FileField, showToast } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const ImportBadgeModal = ({ isOpened, closeModal }) => {
  const [badgeFile, setFile] = useState(null);
  const formData = new FormData();
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const { mutate: importBadge } = useMutation(
    () => {
      activate('importBadge');

      formData.append('pdf_file', badgeFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/pdf',
      };

      return api.post('agency/upload', formData, { headers });
    },
    {
      onSuccess: () => {
        showToast({
          message: 'Le badge a été ajouté avec succès.',
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        closeModal();
        deactivate('importBadge');
      },
    }
  );

  return (
    <Modal
      title="Ajout votre badge"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={importBadge} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p className="content-row">Ajouter votre badge avant d&apos;effectuer une demande d&apos;autorisation</p>

        <FileField label="Importer un fichier" size="content-row medium" accept=".pdf" type="file" onChange={setFile} />
      </form>
    </Modal>
  );
};

ImportBadgeModal.defaultProps = {
  isOpened: false,
  closeModal: null,
};

ImportBadgeModal.propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default ImportBadgeModal;
