import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ToastsProvider } from '@trellixio/roaster-coffee';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from 'App';
import { UserProvider, LoadingProvider, RestClientProvider } from 'context';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <ToastsProvider>
            <RestClientProvider>
              <UserProvider>
                <App />
              </UserProvider>
            </RestClientProvider>
          </ToastsProvider>
        </LoadingProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
