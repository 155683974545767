import React from 'react';
import { Page, Button, TextField, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const PageLayout = ({
  title,
  isFullPage,
  displayPrimaryAction,
  primaryActionLabel,
  primaryAction,
  secondaryActionBtn,
  displayResultBox,
  resultString,
  displaySearchBar,
  searchBarPlaceholder,
  children,
}) => {
  const primaryActionBtn = displayPrimaryAction ? (
    <Button variant="primary" color="success" onClick={primaryAction}>
      <i className="fa-regular fa-solid fa-plus" />
      {primaryActionLabel || 'Ajouter'}
    </Button>
  ) : null;

  return (
    <Page
      title={title}
      size={isFullPage ? 'full' : 'medium'}
      primaryAction={primaryActionBtn}
      secondaryAction={secondaryActionBtn}
    >
      <section className="table-form">
        {displaySearchBar && (
          <form>
            <TextField placeholder={searchBarPlaceholder} prefix={<i className="fa-solid fa-magnifying-glass" />} />
          </form>
        )}
      </section>

      <section className="table-section">
        {displayResultBox && (
          <BlockCard>
            <span className="font-bold">Résultat : </span>
            <span>{resultString}</span>
          </BlockCard>
        )}

        {children}
      </section>
    </Page>
  );
};

PageLayout.defaultProps = {
  title: null,
  isFullPage: true,
  displayPrimaryAction: true,
  primaryActionLabel: null,
  primaryAction: null,
  secondaryActionBtn: null,
  displayResultBox: true,
  resultString: null,
  displaySearchBar: true,
  searchBarPlaceholder: null,
  children: null,
};

PageLayout.propTypes = {
  title: PropTypes.string,
  isFullPage: PropTypes.bool,
  displayPrimaryAction: PropTypes.bool,
  primaryActionLabel: PropTypes.string,
  primaryAction: PropTypes.func,
  secondaryActionBtn: PropTypes.node,
  displayResultBox: PropTypes.bool,
  resultString: PropTypes.string,
  displaySearchBar: PropTypes.bool,
  searchBarPlaceholder: PropTypes.string,
  children: PropTypes.node,
};

export default PageLayout;
