import React, { useState, useContext } from 'react';
import { Button } from '@trellixio/roaster-coffee';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext, UserContext } from 'context';
import { PageLayout } from 'components';
import PilgrimsTable from './components/PilgrimsTable';
import AddPilgrimsModal from './components/AddPilgrimsModal';
import ImportPilgrimsModal from './components/ImportPilgrimsModal';

const Pilgrims = () => {
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [isImportModalOpened, setImportModalOpened] = useState(false);
  const [cursor, setCursor] = useState('');
  const { api } = useContext(RestClientContext);
  const { isAgency } = useContext(UserContext);

  const { data: pilgrims, refetch } = useQuery({
    queryFn: api.list('pilgrim', { cursor }),
    queryKey: api.getQueryKey(),
  });

  const resultString = pilgrims ? `${pilgrims.count} pèlerins` : '-';

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const toggleImportModalOpen = () => setImportModalOpened((val) => !val);

  const secondaryAction = isAgency ? (
    <Button variant="secondary" color="success" onClick={toggleImportModalOpen}>
      <i className="fa-regular fa-solid fa-plus" />
      Ajout multiple
    </Button>
  ) : null;

  return (
    <PageLayout
      title="Pèlerins"
      displayPrimaryAction={isAgency}
      primaryActionLabel="Ajout unique"
      primaryAction={toggleAddModalOpen}
      searchBarPlaceholder="Rechercher un pèlerin"
      secondaryActionBtn={secondaryAction}
      resultString={resultString}
    >
      <PilgrimsTable tableData={pilgrims} setCursor={setCursor} refetchData={refetch} />

      {isAgency && (
        <>
          {isAddModalOpened && (
            <AddPilgrimsModal isOpened={isAddModalOpened} refetchData={refetch} closeModal={toggleAddModalOpen} />
          )}

          {isImportModalOpened && (
            <ImportPilgrimsModal
              isOpened={isImportModalOpened}
              refetchData={refetch}
              closeModal={toggleImportModalOpen}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default Pilgrims;
