export const PRODUCTS_CATEGORIES = [
  {
    label: 'Enregistrement',
    value: 'REGISTRATION',
  },
  {
    label: 'Transport',
    value: 'TRANSPORT',
  },
  {
    label: 'Hébergement',
    value: 'ACCOMMODATION',
  },
  {
    label: 'Nourriture',
    value: 'FOOD',
  },
  {
    label: 'Divers',
    value: 'EXTRA',
  },
];

// export const API_BASE_URL = 'https://api.ehadj.bns.re/api/v1/';

const APP_ENV = process.env.REACT_APP_ENV || 'DEV';

const getEnv = ({ dev, staging, preprod, prod, local }) => {
  if (APP_ENV === 'LOCAL') return local;
  if (APP_ENV === 'STAGING') return staging;
  if (APP_ENV === 'PREPRODUCTION') return preprod;
  if (APP_ENV === 'PRODUCTION') return prod;

  return dev;
};

// export const API_BASE_URL = getEnv({ local: 'http://127.0.0.1:8000/api/v1/', dev: 'https://api.ehadj.bns.re/api/v1/' });
export const API_BASE_URL = getEnv({
  local: 'http://api.ehadj.localhost:8000/api/v1/',
  dev: 'https://api.ehadj.bns.re/api/v1/',
});

export const PILGRIMS_STEPS = [
  {
    label: 'Passeport requis',
    value: 'PASSPORT',
  },
  {
    label: 'Visite médicale',
    value: 'HEALTH',
  },
  {
    label: 'Demande de visa',
    value: 'VISA_SUBMISSION',
  },
  {
    label: 'Visa reçu',
    value: 'VISA_GRANT',
  },
  {
    label: `Billet d'avion`,
    value: 'TRAVEL_TICKET',
  },
  {
    label: 'Processus terminé',
    value: 'COMPLETED',
  },
  {
    label: 'Processus annulé',
    value: 'ABORTED',
  },
  {
    label: `Paiement des frais d'enregistrement`,
    value: 'FEE_REGISTER',
  },
  {
    label: 'Paiement du montant total',
    value: 'FEE_CART',
  },

  {
    label: `Frais d'enregistrement versé à la banque`,
    value: 'INVOICE_REGISTER',
  },
  {
    label: 'Montant total versé à la banque',
    value: 'INVOICE_CART',
  },
];
