import React, { useState, useEffect, useContext } from 'react';
import { Modal, TextField, Alert, Select, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { validateEmail } from 'helpers';
import { Constants } from 'data';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const AddAccountModal = ({ isOpened, organizations, refetchData, closeModal }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(
    organizations.length > 0 ? organizations[0].value : ''
  );
  const { api } = useContext(RestClientContext);

  const { activate, deactivate } = useContext(LoadingContext);

  const newAccount = {
    email,
    password: '',
    first_name: firstName,
    last_name: lastName,
    organization_id: selectedOrganisation,
  };

  const { mutate: createAccount } = useMutation(
    () => {
      activate('createAccount');
      return api.create('user', newAccount);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `L'utilisateur ${firstName} ${lastName} a été ajouté`,
          level: 'success',
          autoClose: true,
        });
      },
      onError: (error) => {
        showToast({
          message: error,
          level: 'error',
          autoClose: true,
        });
      },
      onSettled: () => {
        deactivate('createAccount');
        closeModal();
      },
    }
  );

  useEffect(() => {
    setIsValidForm(validateEmail(email));
  }, [email]);

  useEffect(() => {
    if (organizations.length > 0) setSelectedOrganisation(organizations[0].value);
  }, [organizations]);

  return (
    <Modal
      title="Ajouter une organisation"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={
        <ModalFooter
          primaryBtnDisabled={!isValidForm}
          primaryBtnAction={createAccount}
          secondaryBtnAction={closeModal}
        />
      }
    >
      <form>
        <Select
          label="Organisation"
          data={organizations}
          helpText="Selectionnez l'organisation auquel sera associé cet utilisateur."
          onChange={setSelectedOrganisation}
        />

        <p className="content-row">Entrez les informations de l&apos;utilisateur principal:</p>

        <div className="items-group content-row">
          <TextField
            label="Prénom"
            type="text"
            placeholder={Constants.FIRSTNAME_PLACEHOLDER}
            onChange={setFirstName}
            required
          />
          <TextField
            label="Nom"
            type="text"
            placeholder={Constants.LASTNAME_PLACEHOLDER}
            onChange={setLastName}
            required
          />
        </div>

        <TextField label="Adresse email" inputClassName="content-row" type="email" onChange={setEmail} />

        <Alert level="info" message="Un email de confirmation sera envoyé à l'adresse email" />
      </form>
    </Modal>
  );
};

AddAccountModal.defaultProps = {
  isOpened: false,
  refetchData: null,
  organizations: null,
  closeModal: null,
};

AddAccountModal.propTypes = {
  isOpened: PropTypes.bool,
  refetchData: PropTypes.func,
  organizations: PropTypes.shape([]),
  closeModal: PropTypes.func,
};

export default AddAccountModal;
