import React, { useState, useContext } from 'react';
import { Table, Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { UserContext } from 'context';
import { showPagination, hasNextPage, hasPreviousPage, updateQueryCursor } from 'helpers';
import ConfirmGroupRequestModal from './ConfirmGroupRequestModal';

const GroupTable = ({ tableData, setCursor }) => {
  const [isRequestModalOpened, setRequestModalOpened] = useState(false);
  const [selectedAgencyData, setAgencyData] = useState({});
  const { isAgencyPrimary } = useContext(UserContext);

  const openRequestModal = (orgData) => {
    setAgencyData(orgData);
    setRequestModalOpened((val) => !val);
  };

  return (
    <>
      <Table
        showPagination={showPagination(tableData)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="text">Société</Table.HeadCell>
          <Table.HeadCell type="text">Contact</Table.HeadCell>
          {isAgencyPrimary && <Table.HeadCell type="object">Actions</Table.HeadCell>}
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data, key) => (
              <Table.Row key={data.id} id={key}>
                <Table.Cell type="text">{data.name}</Table.Cell>
                <Table.Cell type="text">{data.admin.email}</Table.Cell>
                {isAgencyPrimary && (
                  <Table.Cell type="object">
                    <Button variant="secondary" onClick={() => openRequestModal(data)}>
                      Faire une demande
                    </Button>
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {Object.keys(selectedAgencyData).length > 0 && isRequestModalOpened && (
        <ConfirmGroupRequestModal
          isOpened={isRequestModalOpened}
          agency={selectedAgencyData}
          closeModal={() => setRequestModalOpened(false)}
        />
      )}
    </>
  );
};

GroupTable.defaultProps = {
  tableData: null,
  setCursor: null,
};

GroupTable.propTypes = {
  tableData: PropTypes.shape([]),
  setCursor: PropTypes.func,
};

export default GroupTable;
