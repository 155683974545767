import React, { useState } from 'react';
import { Button, Modal, Table, TextField } from '@trellixio/roaster-coffee';
import { getFullName, formatDate } from 'helpers';
import PropTypes from 'prop-types';
import { ModalFooter } from 'components';

const AddDepositModal = ({ isOpened, pilgrimData, refetchData, closeModal }) => {
  const [depositAmout, setDepositAmout] = useState(0);

  const { id, pilgrim } = pilgrimData;
  const { first_name: firstname, last_name: lastname } = pilgrim;

  const today = formatDate(new Date());

  const modalAction = () => {
    console.log(id);
    console.log(depositAmout);
    console.log(typeof refetchData);
  };

  return (
    <Modal
      title="Mettre à jour les dépôts"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter displayPrimaryBtn={false} secondaryBtnAction={closeModal} />}
    >
      <p className="content-row">
        Mettre à jour les sommes versées par le pèlerin <span data-role="name">{getFullName(firstname, lastname)}</span>
      </p>

      <Table>
        <Table.Head>
          <Table.HeadCell type="date">Date</Table.HeadCell>
          <Table.HeadCell type="text">Montant</Table.HeadCell>
          <Table.HeadCell type="text">Status</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          <Table.Row id="0">
            <Table.Cell type="text">{today}</Table.Cell>
            <Table.Cell type="text">
              <TextField
                label=""
                type="number"
                placeholder="50000"
                suffix="F CFA"
                required
                onChange={setDepositAmout}
              />
            </Table.Cell>
            <Table.Cell type="text">
              <Button variant="primary" color="success" isIconButton submit onClick={modalAction}>
                <i className="fa-regular fa-plus" />
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Modal>
  );
};

AddDepositModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  refetchData: null,
  closeModal: null,
};

AddDepositModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({
    id: PropTypes.string,
    pilgrim: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AddDepositModal;
