import React, { useContext } from 'react';
import { showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { getFullName } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';
import { ConfirmModalLayout } from 'components';

const DeletePilgrimModal = ({ isOpened, pilgrimData, refetchData, closeModal }) => {
  const { id, pilgrim } = pilgrimData;
  const { first_name: firstname, last_name: lastname } = pilgrim;
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const { mutate: deletePilgrim } = useMutation(
    () => {
      activate('deletePilgrim');
      return api.remove('pilgrim', id);
    },
    {
      onSuccess: ({ data }) => {
        console.log(data);
        refetchData();
        closeModal();
        showToast({
          message: `Le pèlerin ${firstname} ${lastname} a été supprimé`,
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => deactivate('deletePilgrim'),
    }
  );

  const modalText = (
    <span>
      Êtes-vous sûr de vouloir supprimer le pèlerin <span data-role="name">{getFullName(firstname, lastname)}</span> ?
    </span>
  );

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction
      modalTitle="Supprimer un pèlerin"
      modalText={modalText}
      validationBtnText="Supprimer"
      modalAction={deletePilgrim}
      closeModal={closeModal}
    />
  );
};

DeletePilgrimModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  refetchData: null,
  closeModal: null,
};

DeletePilgrimModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({
    id: PropTypes.string,
    pilgrim: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default DeletePilgrimModal;
