import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Urls } from 'data';

const ProtectedRoute = ({ isAllowed }) => {
  if (!isAllowed) {
    return <Navigate to={Urls.base} replace />;
  }

  return <Outlet />;
};

ProtectedRoute.defaultProps = {
  isAllowed: false,
};

ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
};

export default ProtectedRoute;
