import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModalLayout } from 'components';
import { formatAmout } from 'helpers';

const DeleteInvoiceModal = ({ isOpened, invoice, refetchData, closeModal }) => {
  const { id, amount, code } = invoice;

  const modalAction = () => {
    console.log(id);
    console.log(typeof refetchData);
  };

  const modalText = (
    <span>
      Êtes-vous sûr de vouloir supprimer la facture de <span data-role="name">{formatAmout(amount)}</span> ayant pour
      référence <span data-role="name">{code}</span>
    </span>
  );

  return (
    <ConfirmModalLayout
      isOpened={isOpened}
      isDeactivateAction
      modalTitle="Supprimer une facture"
      modalText={modalText}
      validationBtnText="Supprimer"
      modalAction={modalAction}
      closeModal={closeModal}
    />
  );
};

DeleteInvoiceModal.defaultProps = {
  isOpened: false,
  invoice: null,
  refetchData: null,
  closeModal: null,
};

DeleteInvoiceModal.propTypes = {
  isOpened: PropTypes.bool,
  invoice: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    code: PropTypes.string,
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default DeleteInvoiceModal;
