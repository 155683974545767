import React, { useState, useContext } from 'react';
import { Modal, TextField, Textarea, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { Constants } from 'data';
import { validateEmail } from 'helpers';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const AddAgencyModal = ({ isOpened, refetchData, closeModal }) => {
  const [agencyName, setAgencyName] = useState('');
  const [agencyIFU, setAgencyIFU] = useState('');
  const [agencyPhone, setAgencyPhone] = useState('');
  const [agencyAddr, setAgencyAddr] = useState('');
  const [agencyOwnerFirstname, setAgencyOwnerFirstname] = useState('');
  const [agencyOwnerLastname, setAgencyOwnerLastname] = useState('');
  const [agencyOwnerEmail, setAgencyOwnerEmail] = useState('');
  const [nameError, setNameError] = useState(false);
  const [ifuError, setIfuError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addrError, setAddrError] = useState(false);
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastnameError, setLastnameError] = useState(false);
  const [emailError, sEtemailError] = useState(false);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const newAgency = {
    name: agencyName,
    ifu: agencyIFU,
    address: agencyAddr,
    phone: agencyPhone,
    admin_first_name: agencyOwnerFirstname,
    admin_last_name: agencyOwnerLastname,
    admin_email: agencyOwnerEmail,
  };

  const { mutate: createAgency } = useMutation(
    () => {
      activate('createAgency');
      return api.create('agency', newAgency);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `Société ${agencyName} a été ajouté`,
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        showToast({
          message: error,
          level: 'error',
          autoClose: false,
        });
      },
      onSettled: () => {
        closeModal();
        deactivate('createAgency');
      },
    }
  );

  const validateForm = (event) => {
    event.preventDefault();
    const ifuCorrectFormat = /^[0-9]{13}$/.test(agencyIFU);
    const nameErrorLocal = agencyName.length === 0;
    const ifuErrorLocal = !ifuCorrectFormat;
    const phoneErrorLocal = agencyPhone.length === 0;
    const addrErrorLocal = agencyAddr.length === 0;
    const firstnameErrorLocal = agencyOwnerFirstname.length === 0;
    const lastnameErrorLocal = agencyOwnerLastname.length === 0;
    const emailErrorLocal = !validateEmail(agencyOwnerEmail);

    if (
      !nameErrorLocal &&
      !ifuErrorLocal &&
      !phoneErrorLocal &&
      !addrErrorLocal &&
      !firstnameErrorLocal &&
      !lastnameErrorLocal &&
      !emailErrorLocal
    ) {
      createAgency();
    } else {
      setNameError(nameErrorLocal);
      setIfuError(ifuErrorLocal);
      setPhoneError(phoneErrorLocal);
      setAddrError(addrErrorLocal);
      setFirstnameError(firstnameErrorLocal);
      setLastnameError(lastnameErrorLocal);
      sEtemailError(emailErrorLocal);
    }
  };

  return (
    <Modal
      title="Ajouter un société"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={validateForm} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p>Entrez les détails de la société :</p>

        <TextField
          label="Raison sociale (Nom de la société)"
          type="text"
          placeholder="Nom"
          required
          error={nameError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyName}
        />

        <TextField
          label="Numéro IFU"
          type="number"
          placeholder="3202300000000"
          pattern="^[0-9]{13}$"
          minLength="13"
          maxLength="13"
          required
          error={ifuError ? 'Le format du numéro IFU est incorrect.' : ''}
          onChange={setAgencyIFU}
        />

        <TextField
          label="Numéro de Téléphone"
          type="number"
          placeholder="99010203"
          prefix="+229"
          required
          error={phoneError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyPhone}
        />

        <Textarea
          label="Adresse"
          placeholder="Rue, Ville, Departement, Pays"
          required
          error={addrError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyAddr}
        />

        <p>Entrez les informations de l&apos;utilisateur principal :</p>

        <div className="items-group">
          <TextField
            label="Prénom"
            type="text"
            placeholder={Constants.FIRSTNAME_PLACEHOLDER}
            required
            error={firstnameError ? 'Ce champs ne doit pas être vide' : ''}
            onChange={setAgencyOwnerFirstname}
          />

          <TextField
            label="Nom"
            type="text"
            placeholder={Constants.LASTNAME_PLACEHOLDER}
            required
            error={lastnameError ? 'Ce champs ne doit pas être vide' : ''}
            onChange={setAgencyOwnerLastname}
          />
        </div>

        <TextField
          label="Adresse email"
          type="email"
          placeholder={Constants.EMAIL_PLACEHOLDER}
          required
          error={emailError ? 'Adresse email non valide' : ''}
          onChange={setAgencyOwnerEmail}
        />
      </form>
    </Modal>
  );
};

AddAgencyModal.defaultProps = {
  isOpened: false,
  refetchData: null,
  closeModal: null,
};

AddAgencyModal.propTypes = {
  isOpened: PropTypes.bool,
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AddAgencyModal;
