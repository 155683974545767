import React, { useContext, useState } from 'react';
import { Modal, FileField, showToast } from '@trellixio/roaster-coffee';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const ImportPilgrimsModal = ({ isOpened, refetchData, closeModal }) => {
  const [pilgrimsFile, setFile] = useState(null);
  const formData = new FormData();
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const { mutate: importPilgrims } = useMutation(
    () => {
      activate('importPilgrims');

      formData.append('excel_file', pilgrimsFile);

      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      };

      return api.post('pilgrim/upload', formData, { headers });
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: 'Les pèlerins ont été créé avec succès.',
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        closeModal();
        deactivate('importPilgrims');
      },
    }
  );

  return (
    <Modal
      title="Ajout multiple de pèlerins"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={importPilgrims} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p className="content-row">Ajouter plusieurs pèlerins à l&apos;aide d&apos;un fichier excel</p>

        <FileField
          label="Importer un fichier"
          size="content-row medium"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          onChange={setFile}
        />

        <p>
          Télécharger un fichier d&apos;exemple{' '}
          <a href="/pilgrims" target="_blank" rel="noreferrer">
            ici
          </a>{' '}
        </p>
      </form>
    </Modal>
  );
};

ImportPilgrimsModal.defaultProps = {
  isOpened: false,
  refetchData: null,
  closeModal: null,
};

ImportPilgrimsModal.propTypes = {
  isOpened: PropTypes.bool,
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ImportPilgrimsModal;
