import React, { useState, useContext } from 'react';
import { Modal, Table, Radio, showToast } from '@trellixio/roaster-coffee';
import { getFullName, Storage } from 'helpers';
import { Constants } from 'data';
import { UserContext } from 'context';
import PropTypes from 'prop-types';
import { ModalFooter } from 'components';

const HealthCheckModal = ({ isOpened, pilgrimData, refetchData, closeModal }) => {
  const storage = new Storage(localStorage, Constants.APP_NAME); // for demo
  const { user } = useContext(UserContext);
  const { id: userId, first_name: userFirstname, last_name: userLastname } = user; // for demo
  const { id, pilgrim } = pilgrimData;
  const { first_name: firstname, last_name: lastname } = pilgrim;
  const previousReport = storage.getData(`report-${id}`);
  const previousResult = previousReport?.result.split('');

  const [questionI, setQestionI] = useState(previousResult ? previousResult[0] : 'N');
  const [questionII, setQestionII] = useState(previousResult ? previousResult[1] : 'N');
  const [questionIII, setQestionIII] = useState(previousResult ? previousResult[2] : 'N');
  const [questionIV, setQestionIV] = useState(previousResult ? previousResult[3] : 'N');
  const [questionV, setQestionV] = useState(previousResult ? previousResult[4] : 'N');
  const [questionVI, setQestionVI] = useState(previousResult ? previousResult[5] : 'N');
  const [questionVII, setQestionVII] = useState(previousResult ? previousResult[6] : 'N');
  const [questionVIII, setQestionVIII] = useState(previousResult ? previousResult[7] : 'N');
  const [questionIX, setQestionIX] = useState(previousResult ? previousResult[8] : 'N');
  const [questionX, setQestionX] = useState(previousResult ? previousResult[9] : 'N');
  const [questionXI, setQestionXI] = useState(previousResult ? previousResult[10] : 'N');
  const producedResult =
    questionI +
    questionII +
    questionIII +
    questionIV +
    questionV +
    questionVI +
    questionVII +
    questionVIII +
    questionIX +
    questionX +
    questionXI;
  const expectedResult = 'OOOOOONNNNO';

  const report = {
    pilgrimId: id,
    result: producedResult,
    healthy: expectedResult === producedResult,
    doctorId: userId,
    doctorName: getFullName(userFirstname, userLastname),
  };

  // For demo
  const medicalCheckUp = () => {
    storage.setData(`report-${id}`, report);
    refetchData();
    showToast({
      message: `Le pèlerin ${getFullName(firstname, lastname)} a été mise à jour.`,
      level: 'success',
      autoClose: false,
    });
    closeModal();
  };

  return (
    <Modal
      title="Visite médicale"
      size="full"
      className="health-modal"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={medicalCheckUp} secondaryBtnAction={closeModal} />}
    >
      <p className="content-row">
        Entrez les résultats de la visite médicale du pèlerin{' '}
        <span data-role="name">{getFullName(firstname, lastname)}</span> :
      </p>

      <Table>
        <Table.Head>
          <Table.HeadCell type="paragraph">Questions</Table.HeadCell>
          <Table.HeadCell type="object">Résultats</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          <Table.Row id="1">
            <Table.Cell type="paragraph">Vaccination contre la COVID-19 : Pass vaccinal disponible</Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionI}
                onChange={(e) => {
                  setQestionI(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">Vaccination contre la méningite (15 jours à moins de 3 ans)</Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionII}
                onChange={(e) => {
                  setQestionII(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">Vaccination contre la Fièvre jaune (15 jours au moins)</Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionIII}
                onChange={(e) => {
                  setQestionIII(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              Vaccination contre la poliomyélite injectable (15 jours à moins de 3 ans)
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionIV}
                onChange={(e) => {
                  setQestionIV(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">Vaccination contre la fièvre typhoïde (15 jours à moins de 3 ans)</Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionV}
                onChange={(e) => {
                  setQestionV(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              Vaccination contre la grippe hémisphère nord (15 jours à moins de 3 ans)
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionVI}
                onChange={(e) => {
                  setQestionVI(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">trouble psychomoteur (incohérence, désorientation, .....)</Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionVII}
                onChange={(e) => {
                  setQestionVII(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              troubles locomoteurs (incapable de se déplacer, nécessite l’aide d’un tiers pour se déplacer, poids
              excessif ne permettant pas la marche....)
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionVIII}
                onChange={(e) => {
                  setQestionVIII(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              {' '}
              troubles importants de la vision (impossible de voir les personnes autour, impossible de voir des
              obstacles dans les 5-20 m)
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionIX}
                onChange={(e) => {
                  setQestionIX(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              présence de maladie chronique grave (insuffisance rénale chronique, diabète ou HTA non stabilisé, asthme
              non stable, inobservance de traitement de diabète de HTA ou de diabète)
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionX}
                onChange={(e) => {
                  setQestionX(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>

          <Table.Row id="1">
            <Table.Cell type="paragraph">
              disponibilité de traitement pour un mois au moins en cas de maladie chronique stabilisé
            </Table.Cell>
            <Table.Cell type="text">
              <Radio.Group
                title=""
                orientation="horizontal"
                defaultValue={questionXI}
                onChange={(e) => {
                  setQestionXI(e);
                }}
              >
                <Radio value="O" label="Oui" />
                <Radio value="N" label="Non" />
              </Radio.Group>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Modal>
  );
};

HealthCheckModal.defaultProps = {
  isOpened: false,
  pilgrimData: null,
  refetchData: null,
  closeModal: null,
};

HealthCheckModal.propTypes = {
  isOpened: PropTypes.bool,
  pilgrimData: PropTypes.shape({
    id: PropTypes.string,
    pilgrim: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default HealthCheckModal;
