import React from 'react';
import { Page, BlockCard, DescriptiveList } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const StatusDetails = ({ pilgrim }) => {
  console.log(pilgrim);
  return (
    <Page size="medium" className="vcenter">
      <div className="auth-form-container">
        <BlockCard>
          <DescriptiveList>
            <DescriptiveList.Item title="Prénom">Lorem ipsum Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Nom">Lorem ipsum Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Société agréée">Lorem ipsum Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Passeport">Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Visite médicale">Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Paiements des frais">Lorem ipsum</DescriptiveList.Item>
            <DescriptiveList.Item title="Visa">Lorem ipsum</DescriptiveList.Item>
          </DescriptiveList>
        </BlockCard>
      </div>
    </Page>
  );
};

StatusDetails.defaultProps = {
  pilgrim: null,
};

StatusDetails.propTypes = {
  pilgrim: PropTypes.shape({}),
};

export default StatusDetails;
