// eslint-disable-next-line max-classes-per-file
class ExtendableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class APIError extends ExtendableError {}

export class APIError400BadRequest extends APIError {}
export class APIError403Forbidden extends APIError {}
export class APIError404NotFound extends APIError {}
export class APIError409Conflict extends APIError {}
export class APIError50XServerError extends APIError {}

/**
 *
 * @param {object} apiError object return by a fail API call
 * @param {Function} onLogout Logout function
 * @returns {Promise} a Promise
 */
export async function raiseAPIError(apiError, onLogout) {
  if (apiError.status === 401) return onLogout();

  return Promise.reject(apiError.data.detail);
}
