import React, { useState, useContext } from 'react';
import { Table, Button } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { UserContext } from 'context';
import { getFullName, formatDate, showPagination, hasNextPage, hasPreviousPage, updateQueryCursor } from 'helpers';
import { ActivationTag } from 'components';
import adminIcon from 'assets/img/admin.svg';
import EditAccountModal from './EditAccountModal';
import UpdateAccountStatusModal from './UpdateAccountStatusModal';

const AccountsTable = ({ tableData, setCursor, refetchData }) => {
  const [isUpdateStatusModalOpened, setUpdateStatusModalOpened] = useState(false);
  const [isEditModalOpened, setEditModalOpened] = useState(false);
  const [selectedOrgData, setOrgData] = useState({});
  const { email } = useContext(UserContext);

  const openUpdateStatusModal = (orgData) => {
    setOrgData(orgData);
    setUpdateStatusModalOpened((val) => !val);
  };

  const openEditModal = (orgData) => {
    setOrgData(orgData);
    setEditModalOpened((val) => !val);
  };

  const isConnectedUser = (userEmail) => email === userEmail;

  return (
    <>
      <Table
        showPagination={showPagination(tableData)}
        hasNextPage={hasNextPage(tableData)}
        hasPreviousPage={hasPreviousPage(tableData)}
        onNextPage={() => updateQueryCursor(tableData.next, setCursor)}
        onPreviousPage={() => updateQueryCursor(tableData.previous, setCursor)}
      >
        <Table.Head>
          <Table.HeadCell type="thumbnail" />
          <Table.HeadCell type="text">Nom</Table.HeadCell>
          <Table.HeadCell type="text">Email</Table.HeadCell>
          <Table.HeadCell type="paragraph">Organisation</Table.HeadCell>
          <Table.HeadCell
            type="enum"
            hasTooltip={false}
            tooltipProps={{
              label: `Status de l'activation du compte de l'utilisateur.`,
            }}
          >
            {`Actif `}
          </Table.HeadCell>
          <Table.HeadCell
            type="date"
            hasTooltip={false}
            tooltipProps={{
              label: `Date de création de l'utilisateur.`,
            }}
          >
            {`Création `}
          </Table.HeadCell>
          <Table.HeadCell type="object">Actions</Table.HeadCell>
        </Table.Head>

        {tableData && tableData.length > 0 && (
          <Table.Body>
            {tableData.map((data, key) => (
              <Table.Row key={data.id} id={key} className={`${isConnectedUser(data.email) ? 'selected' : ''}`}>
                <Table.Cell type="thumbnail">
                  <img src={adminIcon} alt="" />
                </Table.Cell>
                <Table.Cell type="text">
                  <div className="items-group">{getFullName(data.first_name, data.last_name)}</div>
                </Table.Cell>
                <Table.Cell type="text">{data.email}</Table.Cell>

                <Table.Cell type="paragraph">{data.organization.name}</Table.Cell>
                <Table.Cell type="enum">
                  <ActivationTag isActive={data.is_active} />
                </Table.Cell>
                <Table.Cell type="text">{formatDate(data.created)}</Table.Cell>
                <Table.Cell type="text">
                  {!isConnectedUser(data.email) && data.is_active && (
                    <div className="items-group">
                      <Button variant="secondary" isIconButton onClick={() => openEditModal(data)}>
                        <i className="fa-regular fa-solid fa-pen" />
                      </Button>
                      <Button
                        variant="secondary"
                        color="danger"
                        isIconButton
                        onClick={() => openUpdateStatusModal(data)}
                      >
                        <i className="fa-regular fa-solid fa-ban" />
                      </Button>
                    </div>
                  )}

                  {!isConnectedUser(data.email) && !data.is_active && (
                    <Button variant="secondary" isIconButton onClick={() => openUpdateStatusModal(data)}>
                      <i className="fa-solid fa-rotate-right" />
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>

      {Object.keys(selectedOrgData).length > 0 && (
        <>
          {isEditModalOpened && (
            <EditAccountModal
              user={selectedOrgData}
              isOpened={isEditModalOpened}
              refetchData={refetchData}
              closeModal={() => setEditModalOpened(false)}
            />
          )}

          {isUpdateStatusModalOpened && (
            <UpdateAccountStatusModal
              user={selectedOrgData}
              isOpened={isUpdateStatusModalOpened}
              refetchData={refetchData}
              closeModal={() => setUpdateStatusModalOpened(false)}
            />
          )}
        </>
      )}
    </>
  );
};

AccountsTable.defaultProps = {
  tableData: null,
  setCursor: null,
  refetchData: null,
};

AccountsTable.propTypes = {
  tableData: PropTypes.shape([]),
  setCursor: PropTypes.func,
  refetchData: PropTypes.func,
};

export default AccountsTable;
