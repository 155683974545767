import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

const SidebarItem = ({ itemName, path, itemIcon }) => {
  const isActive = useMatch(path) !== null;

  return (
    <li className={`${isActive ? 'active' : ''}`}>
      <Link to={path} className="items-group">
        <img src={itemIcon} alt={itemName} />
        {itemName}
      </Link>
    </li>
  );
};

SidebarItem.defaultProps = {
  itemName: null,
  path: null,
  itemIcon: null,
};

SidebarItem.propTypes = {
  itemName: PropTypes.string,
  path: PropTypes.string,
  itemIcon: PropTypes.node,
};

export default SidebarItem;
