import React, { useState, useContext } from 'react';
import { PageLayout } from 'components';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext } from 'context';
import ProductsTable from './components/ProductsTable';
import AddProductModal from './components/AddProductModal';

const Products = () => {
  const [isAddModalOpened, setAddModalOpened] = useState(false);
  const [cursor, setCursor] = useState('');
  const { api } = useContext(RestClientContext);

  const toggleAddModalOpen = () => setAddModalOpened((val) => !val);

  const { data: products, refetch } = useQuery({
    queryFn: api.list('product', { cursor }),
    queryKey: api.getQueryKey(),
  });

  const resultString = products ? `${products.count} produits` : '-';

  return (
    <PageLayout
      title="Produits"
      displayPrimaryAction
      searchBarPlaceholder="Rechercher un produit"
      primaryAction={toggleAddModalOpen}
      resultString={resultString}
    >
      <ProductsTable tableData={products} setCursor={setCursor} refetchData={refetch} />

      {isAddModalOpened && (
        <AddProductModal isOpened={isAddModalOpened} refetchData={refetch} closeModal={toggleAddModalOpen} />
      )}
    </PageLayout>
  );
};

export default Products;
