import React, { useState, useContext } from 'react';
import { Modal, TextField, Textarea, showToast } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { LoadingContext, RestClientContext } from 'context';
import { ModalFooter } from 'components';

const EditAgencyModal = ({ isOpened, agency, refetchData, closeModal }) => {
  const { id, name: agencyName, ifu: agencyIFU, phone: agencyPhone, address: agencyAddr, admin } = agency;
  const { first_name: firstname, last_name: lastname, email } = admin;

  const [name, setAgencyName] = useState(agencyName);
  const [ifu, setAgencyIFU] = useState(agencyIFU);
  const [phone, setAgencyPhone] = useState(agencyPhone);
  const [address, setAgencyAddr] = useState(agencyAddr);
  const [nameError, setNameError] = useState(false);
  const [ifuError, setIfuError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addrError, setAddrError] = useState(false);
  const { api } = useContext(RestClientContext);
  const { activate, deactivate } = useContext(LoadingContext);

  const editedAgency = {
    name,
    ifu,
    address,
    phone,
    admin_first_name: firstname,
    admin_last_name: lastname,
    admin_email: email,
  };

  const { mutate: editAgency } = useMutation(
    () => {
      activate('editAgency');
      return api.update('agency', editedAgency, id);
    },
    {
      onSuccess: () => {
        refetchData();
        showToast({
          message: `Société ${name} été mise à jour.`,
          level: 'success',
          autoClose: false,
        });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: () => {
        closeModal();
        deactivate('editAgency');
      },
    }
  );

  const validateForm = (event) => {
    event.preventDefault();
    const ifuCorrectFormat = /^[0-9]{13}$/.test(ifu);
    const nameErrorLocal = name.length === 0;
    const ifuErrorLocal = !ifuCorrectFormat;
    const phoneErrorLocal = phone.length === 0;
    const addrErrorLocal = address.length === 0;

    if (!nameErrorLocal && !ifuErrorLocal && !phoneErrorLocal && !addrErrorLocal) {
      editAgency();
    } else {
      setNameError(nameErrorLocal);
      setIfuError(ifuErrorLocal);
      setPhoneError(phoneErrorLocal);
      setAddrError(addrErrorLocal);
    }
  };

  return (
    <Modal
      title="Ajouter un société"
      size="medium"
      opened={isOpened}
      onClose={closeModal}
      footer={<ModalFooter primaryBtnAction={validateForm} secondaryBtnAction={closeModal} />}
    >
      <form>
        <p>{`Entrez les détails de la société ${agencyName} :`}</p>

        <TextField
          label="Raison sociale (Nom de la société)"
          type="text"
          placeholder="Nom"
          defaultValue={agencyName}
          required
          error={nameError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyName}
        />

        <TextField
          label="Numéro IFU"
          type="number"
          defaultValue={agencyIFU}
          pattern="^[0-9]{13}$"
          minLength="13"
          maxLength="13"
          required
          error={ifuError ? 'Le format du numéro IFU est incorrect.' : ''}
          onChange={setAgencyIFU}
        />

        <TextField
          label="Numéro de Téléphone"
          type="number"
          defaultValue={agencyPhone}
          prefix="+229"
          required
          error={phoneError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyPhone}
        />

        <Textarea
          label="Adresse"
          placeholder="Rue, Ville, Departement, Pays"
          defaultValue={agencyAddr}
          required
          error={addrError ? 'Ce champs ne doit pas être vide' : ''}
          onChange={setAgencyAddr}
        />
      </form>
    </Modal>
  );
};

EditAgencyModal.defaultProps = {
  isOpened: false,
  agency: null,
  refetchData: null,
  closeModal: null,
};

EditAgencyModal.propTypes = {
  isOpened: PropTypes.bool,
  agency: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    ifu: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    admin: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
  refetchData: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditAgencyModal;
