import React, { useContext } from 'react';
import { PageLayout } from 'components';
import { useQuery } from '@tanstack/react-query';
import { RestClientContext } from 'context';
import InvoicesTable from './components/InvoicesTable';

const Invoices = () => {
  const { api } = useContext(RestClientContext);

  const { data: invoices, refetch } = useQuery({
    queryFn: api.list('bank/invoice'),
    queryKey: api.getQueryKey(),
  });

  const resultString = invoices ? `${invoices.count} facture` : '-';

  return (
    <PageLayout
      title="Factures"
      displayPrimaryAction={false}
      searchBarPlaceholder="Rechercher une facture"
      resultString={resultString}
    >
      <InvoicesTable tableData={invoices} refetchData={refetch} />
    </PageLayout>
  );
};

export default Invoices;
