import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const LoadingContext = React.createContext([]);

const LoadingProvider = ({ children }) => {
  const [loadingElements, setLoadingElements] = useState([]);

  const activate = (elementName) => setLoadingElements([...loadingElements, elementName]);

  const deactivate = (elementName) => setLoadingElements(loadingElements.filter((e) => e !== elementName));

  const clear = () => setLoadingElements([]);

  const isActive = loadingElements.length > 0;

  const getIsElementActive = (elementName) => {
    return loadingElements.includes(elementName);
  };

  const memoValues = useMemo(
    () => ({
      activate,
      deactivate,
      isActive,
      getIsElementActive,
      clear,
    }),
    [loadingElements]
  );

  return <LoadingContext.Provider value={memoValues}>{children}</LoadingContext.Provider>;
};

LoadingProvider.defaultProps = {
  children: null,
};

LoadingProvider.propTypes = {
  children: PropTypes.node,
};

export default LoadingProvider;
