import React from 'react';
import { Page, BlockCard } from '@trellixio/roaster-coffee';
import PropTypes from 'prop-types';

const AuthLayout = ({ title, inputFields, actionButtons }) => {
  return (
    <Page size="medium" className="vcenter">
      <div className="auth-form-container">
        <BlockCard title={title} className="login-card">
          <form>
            <div className="card-body">{inputFields}</div>

            <div className="card-footer">{actionButtons}</div>
          </form>
        </BlockCard>
      </div>
    </Page>
  );
};

AuthLayout.defaultProps = {
  title: null,
  inputFields: null,
  actionButtons: null,
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  inputFields: PropTypes.node,
  actionButtons: PropTypes.node,
};

export default AuthLayout;
