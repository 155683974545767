import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Urls } from 'data';
import { UserContext } from 'context';

const LandingPage = () => {
  const { isSuperAdmin, isConnected, isBank } = useContext(UserContext);

  if (isSuperAdmin) {
    return <Navigate to={Urls.accounts} replace />;
  }

  if (isBank) {
    return <Navigate to={Urls.invoices} replace />;
  }

  if (isConnected && !isSuperAdmin && !isBank) {
    return <Navigate to={Urls.pilgrims} replace />;
  }

  return <Navigate to={Urls.front.login} replace />;
};

export default LandingPage;
