const Constants = {
  APP_NAME: 'eHadj',
  USER: 'user',
  USER_TOKEN: 'user_token',
  EMAIL_PLACEHOLDER: 'email@example.com',
  FIRSTNAME_PLACEHOLDER: 'Mohamed',
  LASTNAME_PLACEHOLDER: 'COMAN',
  NPI_PLACEHOLDER: '0123456789',
  PASSPORT_PLACEHOLDER: '01JJ01011',
};

export default Constants;
